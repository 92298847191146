﻿define(['jquery', 'pubsub', 'moduleUtilities', 'generalUtilities'], function navigationDialpadControl(
	$,
	pubsub,
	moduleUtilities,
	generalUtilities
) {
	/**
	 * re_navigation_dialpad_control
	 * @exports navigationDialpadControl
	 * @author John Allan
	 */
	//#region set up
	'use strict';

	var module, selectors, elements;

	module = {};
	module.private = {};
	module.properties = {};
	selectors = {
		accordionOpen: '.accordion-open',
		accordion: '.js-nav-accordion',
		toggleAccordion: '.js-toggle-accordion',
		experienceNav: '.js-nav-secondary-experiences'
	};
	elements = {};

	//#endregion

	//#region pubsub event handlers

	/**
	 * Toggles experiences submenus
	 * @param {object} [e] the event
	 */
	module.private.handleToggleAccordionClick = function handleToggleAccordionClick(e) {
		var $this = $(e.currentTarget);
		e.preventDefault();
		e.stopPropagation();

		$(selectors.accordionOpen)
			.not($this.parent().siblings(selectors.accordion))
			.removeClass('accordion-open')
			.slideUp('fast');
		$(selectors.toggleAccordion + '.toggle-open')
			.not($this)
			.removeClass('toggle-open');
		$this
			.toggleClass('toggle-open')
			.parent()
			.siblings(selectors.accordion)
			.toggleClass('accordion-open')
			.slideToggle('fast');
	};

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		// module config
		elements.experienceNav = $(selectors.experienceNav);

		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		elements.toggleAccordion = $(selectors.toggleAccordion);

		elements.toggleAccordion
			.off('click.NavigationDialpadControl')
			.on('click.NavigationDialpadControl', module.private.handleToggleAccordionClick);
	};

	//#region external event bindings

	module.private.init();

	//#endregion

	// expose API
	return module;
});
