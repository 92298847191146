﻿define([
	'jquery',
	'pubsub',
	'moduleUtilities',
	'generalUtilities',
	'notificationToolbarListCompiled',
	'moment',
	'notificationsEnumData',
	'notificationService'
], function notificationToolbarGenericListControl(
	$,
	pubsub,
	moduleUtilities,
	generalUtilities,
	notificationToolbarListCompiled,
	moment,
	notificationsEnumData
) {
	/**
	 * re_notification_list_control
	 * @exports notificationToolbarGenericListControl
	 * @author John Allan
	 */

	'use strict';

	var module, selectors, elements, values;

	module = {};
	module.private = {};
	module.properties = {};
	selectors = {
		notificationListWrapper: '.re-nav-notifications',
		notificationList: '.js-notifications-list',
		notificationIcon: '.js-notification'
	};
	elements = {};
	values = {
		requesterRegexObj: /title="(.[^"]+)"/g,
		positionScroll: 0
	};

	/**
	 * Publish a notification browse request
	 */
	module.private.notificationGenericBrowse = function notificationGenericBrowse() {
		var request, requestOptions, serviceObject, notificationTypes;
		// seen in the request/response but not passed to the webservice (same as module.properties)
		requestOptions = {};
		notificationTypes = notificationsEnumData.browse();

		// sent to webservice
		request = {
			RecordCount: 10,
			IncludeDismissed: true,
			ExcludeNotificationTypes: [
				notificationTypes.InviteToConnectNotification,
				notificationTypes.InviteToSellingCommunityNotification
			]
		};
		serviceObject = moduleUtilities.createServiceObject(request, module.properties, requestOptions);

		// service needs to be using publish2
		pubsub.publish('uiNeeds.NotificationBrowse2', serviceObject);
	};

	/**
	 * massage data in irder to prettify date and format count message
	 * @param {object} data Data object from service.
	 */
	module.private.massageData = function massageData(data) {
		var banner;
		if (Array.isArray(data.Data)) {
			data.Data.forEach(function dataDataForEach(item) {
				// prettify/relativize dates
				if (item.hasOwnProperty('DateCreated')) {
					if (generalUtilities.prop(item, 'DateCreated')) {
						item.RelativeDate = moment(item.DateCreated + '+00:00').fromNow();
					}
				}

				if (item.hasOwnProperty('NotificationType')) {
					item.NotificationTypeName = 'generic';
				}
			});

			// format count message
			data.notificationCountMessage =
				data.Data.length === 1
					? RESAAS.Localization.Global.NOTIFICATIONS_COUNT_MESSAGE_SINGULAR
					: generalUtilities.stringFormat(
							RESAAS.Localization.Global.NOTIFCATINOS_COUNT_MESSAGE_PLURAL,
							data.Data.length
					  );

			// inject premium banner
			if (data.Data.length && generalUtilities.prop(window, 'RESAAS.Environment.Experience') !== "commercial") {
				banner = { banner: true };

				if (!generalUtilities.prop(window, 'RESAAS.User2.ReferralPro') && !generalUtilities.prop(window, 'RESAAS.User2.PremiumPlusBadge')) {
					banner.upsell = true;
					
				}
				else if (generalUtilities.prop(window, 'RESAAS.User2.PremiumPlusBadge')){
					banner.premiumplus = true;
				}

				data.Data.splice(3, 0, banner);
			}
		}
	};

	/**
	 * Handle data event returned from service, determines success of fail and calls the corresponding function.
	 * @param {object} e The pubsub event.
	 * @param {object} data Data object from service.
	 */
	module.private.handleDataGenericNotificationBrowse = function handleDataGenericNotificationBrowse(e, data) {
		if (generalUtilities.prop(data, 'response.Data')) {
			module.private.handleDataGenericNotificationBrowseSuccess(e, data);
		} else {
			module.private.handleDataGenericNotificationBrowseError(e, data);
		}
	};

	/**
	 * Handle data event returned from service, determines success of fail and calls the corresponding function.
	 * @param {object} e The pubsub event.
	 * @param {object} data Data object from service.
	 */
	module.private.handleDataGenericNotificationBrowseSuccess = function handleDataGenericNotificationBrowseSuccess(
		e,
		data
	) {
		var response, notificationTypes, includedNotificationTypes;

		notificationTypes = notificationsEnumData.browse();
		includedNotificationTypes = generalUtilities.prop(data, 'request.IncludeNotificationTypes');

		// if connection requests are NOT the only notifications retrieved hide the unread indicator (red dot)
		if (
			!(
				includedNotificationTypes &&
				includedNotificationTypes.length === 1 &&
				includedNotificationTypes.indexOf(notificationTypes.InviteToConnectNotification) === 0
			)
		) {
			elements.notificationList.parent().removeClass('re-nav-hasactivity');
		}

		// if not own event exit
		if (!moduleUtilities.isOwnEvent(data, module.properties)) {
			return;
		}

		// show notification list
		response = generalUtilities.prop(data, 'response');
		response.Success = true;

		if (generalUtilities.prop(data, 'response.Count')) {
			module.private.massageData(response);
		}

		response.historyLink = 'notifications';
		response.historyLinkText = RESAAS.Localization.Navigation.VIEW_NOTIFICATIONS_HISTORY;
		response.dismissAllowed = false;
		response.NoNotificationMessage = RESAAS.Localization.Global.TOOLBAR_NO_NEW_NOTIFICATIONS;

		// inject the notification list
		elements.notificationList.html(notificationToolbarListCompiled(response));
	};

	/**
	 * Handles error types and publishes to loggly.
	 * @param {object} e The pubsub event.
	 * @param {object} data Data object from service.
	 */
	module.private.handleDataGenericNotificationBrowseError = function handleDataGenericNotificationBrowseError(e, data) {
		var status, errMsg;

		if (moduleUtilities.isOwnEvent(data, module.properties)) {
			status = generalUtilities.prop(data, 'response.status');
			switch (status) {
				case '401':
					errMsg = RESAAS.Localization.Global.Unauthorized_USER_ERROR_MESSAGE;
					break;
				case '500':
					errMsg = RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE;
					break;
				default:
					errMsg = RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE;
					break;
			}

			pubsub.publish('loggly.error', {
				message: generalUtilities.stringFormat(
					'connection browse failed to retrieve results: {0}',
					JSON.stringify(data.response)
				)
			});

			pubsub.publish('uiAction.MessageAdd', {
				message: errMsg,
				type: 'error'
			});
		}
	};

	/**
	 * Handle click on the notification icon, hide/shows notification list
	 * @param {object} e Click event
	 */
	module.private.handleNotificationIconClick = function handleNotificationIconClick(e) {
		// Preventing scrolling mobile notification menu is open
		if (
			$(e.currentTarget)
				.parent()
				.hasClass('re-nav-open')
		) {
			values.positionScroll = elements.window.scrollTop();
			elements.body.css({ top: '-' + values.positionScroll + 'px' });
			elements.html.addClass('re-compact-overflow-y-hidden');
		} else {
			elements.html.removeClass('re-compact-overflow-y-hidden');
			elements.window.scrollTop(values.positionScroll);
		}

		pubsub.publish('mixpanel.Intent', {
			type: 'open notifications dropdown'
		});

		// the timeout is here to ensure it happens at the bottom of the event stack
		// the tree control does the reveal before this check takes place which is why
		// we call the service if the list is visible and not the other way around
		// @john.allan
		setTimeout(function iconClickTimeout() {
			if (
				$(e.currentTarget)
					.next(selectors.notificationList)
					.is(':visible')
			) {
				module.private.notificationGenericBrowse();
			}
		}, 0);
	};

	module.private.handleUpsellClick = function handleUpsellClick(e) {
		if (generalUtilities.prop(window, 'RESAAS.MixPanel.trackEvent')) {
			e.preventDefault();
			RESAAS.MixPanel.trackEvent(
				'Intent',
				{
					type: e.target.getAttribute('data-mixpanel-type'),
					from: e.target.getAttribute('data-mixpanel-from')
				},
				e.target.getAttribute('href')
			);
		}
	};

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		// elements
		elements.html = $('html');
		elements.window = $(window);
		elements.body = $('body');
		elements.notificationListWrapper = $(selectors.notificationListWrapper);
		elements.notificationList = $(selectors.notificationList);

		// moment
		generalUtilities.configureMoment(moment);

		// dom event binding
		elements.notificationListWrapper.on(
			'click',
			selectors.notificationIcon,
			module.private.handleNotificationIconClick
		);

		elements.notificationListWrapper.on('click', '.re-notification-upsell .re-btn', module.private.handleUpsellClick);

		// inject pending message
		elements.notificationList.html(notificationToolbarListCompiled({ pendingMessage: true }));
	};

	pubsub.subscribe('data.NotificationBrowse2', module.private.handleDataGenericNotificationBrowse);
	pubsub.subscribe('uiAction.NotificationBrowse', module.private.notificationGenericBrowse);

	module.private.init();

	// expose API
	return module;
});
