﻿/**
 * re_navigation_invite_control
 * @exports navigationInviteControl
 * @author Suen Ting
 */
define(['generalUtilities', 'moduleUtilities', 'validator', 'pubsub'], function navigationInviteControl(
    generalUtilities,
    moduleUtilities,
    validator,
    pubsub
) {
    var module, selectors, elements;

    module = {};
    module.private = {};
    module.properties = {};
    selectors = {
        inviteIcon: '.js-invite',
        inputHref: '#js-invite-href',
        copyButton: '#js-invite-copy',
        inviteForm: '.js-invite-form',
        inviteOption: '.js-invite-option',
        inviteBtn: '#js-invite-btn',
        errorMessage: '.js-invite-form .error',
        email: '#InviteEmail',
        inviteWithEmailForm: '#invite-with-email'
    };

    elements = {};

    var constraints = {
        InviteEmail: {
            email: {
                message: ['^', RESAAS.Localization.Navigation.INVITE_WITH_EMAIL_VALIDATION].join(' ')
            },
            presence: {
                message: ['^', RESAAS.Localization.Navigation.INVITE_WITH_EMAIL_VALIDATION].join(' ')
            }
        }
    };

    module.private.inviteClicked = function inviteClicked() {
        pubsub.publish('mixpanel.Intent', {
            type: 'open invite widget',
            context: 'toolbar invite'
        });
    };

    module.private.copyInviteClicked = function copyInviteClicked() {
        elements.inputHref.focus(); // required to highlight text on older browsers (pre ios10) as document.execCommand("copy") wont work
        elements.inputHref.select();
        elements.inputHref.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand('copy');

        pubsub.publish('mixpanel.Intent', {
            type: 'copy invite url',
            context: 'toolbar invite'
        });
    };

    module.private.inviteOptionClicked = function inviteOptionClicked(e) {
        elements.inviteOption.toggleClass('re-invite-option-selected');
        elements.inviteForm.toggleClass('hide');
        pubsub.publish('mixpanel.Intent', {
            type: e.currentTarget.dataset.mixpaneltype,
            context: 'toolbar invite'
        });
    };

    /**
     * Handle click on the action, Invite a user
     * @param {object} e Click event
     */
    module.private.inviteBtnClicked = function(e) {
        var requestOptions, request, serviceObject, email, invalid;
        email = elements.email.val();

        invalid = validator.validate(elements.inviteWithEmailForm, constraints);

        if (invalid) {
            validator.showErrors(elements.inviteWithEmailForm, invalid, false);
            return;
        }

        elements.inviteWithEmailForm.find(":input, .re-input-wrapper").removeClass('error');
        elements.inviteWithEmailForm.find('.error-message').remove();

        requestOptions = {};

        request = {
            contact: {
                Name: 'there',
                Email: email,
                Invite: true
            }
        };

        serviceObject = moduleUtilities.createServiceObject(request, module.properties, requestOptions);

        generalUtilities.disableAndWaitFor(e.target, 'data.ConnectionAdd');
        pubsub.publish('uiHas.ConnectionAdd', serviceObject);
        pubsub.publish('mixpanel.Intent', {
            type: 'Sent invite email',
            context: 'toolbar invite'
        });
    };

    /**
     * Invite response error handler
     * @param {object} e event
     * @param {object} data response data
     */
    module.private.handleDataInviteError = function(e, data) {
        pubsub.publish('loggly.error', { message: JSON.stringify(data) });

        pubsub.publish('uiAction.MessageAdd', {
            message: RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE,
            type: 'error'
        });
    };

    /**
     * Invite response success handler
     * @param {object} e event
     * @param {object} data response data
     */
    module.private.handleDataInviteSuccess = function(e, data) {
        pubsub.publish('uiAction.MessageAdd', {
            message: window.RESAAS.Localization.Navigation.INVITE_AGENT_SUCCEEDED
        });
    };

    /**
     * Handle data event returned from service, determines success of fail and calls the corresponding function.
     * @param {object} e The pubsub event.
     * @param {object} data Data object from service.
     */
    module.private.handleDataInvite = function(e, data) {
        var success;
        elements.email.val('');
        if (moduleUtilities.isOwnEvent(data, module.properties)) {
            success = generalUtilities.prop(data, 'response.SaveResult.Success');
            if (!success) {
                module.private.handleDataInviteError(e, data);
            } else {
                module.private.handleDataInviteSuccess(e, data);
            }
        }
    };

    /**
     * Initiates the module, creates a guid and binds dom events.
     * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
     * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
     */
    module.private.init = function init(e, data) {
        var applicationUrl, domainUrl, returnUrl, queryParams;
        
        //module config
        module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

        applicationUrl = generalUtilities.prop(RESAAS, 'Environment.ApplicationUrl');
        domainUrl = generalUtilities.prop(RESAAS, 'User2.DomainUrl');
        returnUrl = encodeURIComponent(generalUtilities.stringFormat('{0}/profile', domainUrl));

        // elements
        elements.inviteIcon = document.querySelector(selectors.inviteIcon);
        elements.inputHref = document.querySelector(selectors.inputHref);
        elements.copyButton = document.querySelector(selectors.copyButton);
        elements.inviteOption = $(selectors.inviteOption);
        elements.inviteForm = $(selectors.inviteForm);
        elements.email = $(selectors.email);
        elements.inviteBtn = document.querySelector(selectors.inviteBtn);
        elements.errorMessage = document.querySelector(selectors.errorMessage);
        elements.inviteWithEmailForm = $(selectors.inviteWithEmailForm);

        // note: elements are not defined on initialization in karma. or for regular users
        if (!elements.inviteIcon || !elements.inputHref || !elements.copyButton || !elements.inviteBtn) {
            return;
        }
        elements.inputHref.value = generalUtilities.stringFormat(
            '{0}/signup?ReturnUrl={1}&utm_source=RESAAS+Users&utm_medium=Email&utm_content=Toolbar+Invite+URL&utm_campaign=Invite+Users',
            applicationUrl,
            returnUrl
        );

        elements.copyButton.addEventListener('click', module.private.copyInviteClicked);
        elements.inviteIcon.addEventListener('click', module.private.inviteClicked);
        elements.inputHref.addEventListener('click', module.private.copyInviteClicked);
        elements.inviteBtn.addEventListener('click', module.private.inviteBtnClicked);
        elements.inviteOption.on('click', module.private.inviteOptionClicked);
        // handle user invite hashchange
        window.addEventListener('hashchange', function (event) {
            if (window.location.hash.indexOf('invite') !== -1) {
                document.querySelector('.js-invite').click();
                module.private.inviteClicked();
                window.location.hash = ""
            }
        });
    };

    pubsub.subscribe('data.ConnectionAdd', module.private.handleDataInvite);

    module.private.init();

    return module;
});
