﻿/**
 * @fileOverview re_navigation_initialiser.js
 *
 * @author John Allan
 *
 */
define([
    'navigationControl',
    'notificationsDrawerControl',
    'mixpanelControl',
    'languageControl',
    'shareWidgetControl'
], function navigationInitialiser() {
    'use strict';
});
