﻿define([
	'pubsub', 'generalUtilities', 'stickySidebar'],

	function (pubsub, generalUtilities) {
		'use strict';
		/**
		* re_navigation_scroll_control
		* @exports navigationScrollControl
		* @author John Allan
		*/
		//#region set up

		var module, selectors, elements, values;

		module = {};
		/** @module */
		module.private = {};
		module.properties = {};

		selectors = {
			leftNavigation: '.re-page-navigation',
			pageContent: '.re-page-content',
			pageWrapper: '.re-page-wrapper',
			stickySidebar: '.js-stickysidebar',
			stickySidebarInner: '.js-stickysidebar-inner'
		};

		elements = {};
		values = {};

		//#endregion

		//#region utilities

		/**
		* Sticks the navigation.
		* @example
		* module.private.stickNavigation();
		*/
		module.private.stickNavigation = function () {
			if (values.navigation) {
				module.private.unStickNavigation();
			}

			//setup sticky nav
			values.navigation = new StickySidebar(selectors.stickySidebar, {
				containerSelector: selectors.pageWrapper,
				innerWrapperSelector: selectors.stickySidebarInner,
				topSpacing: 70,
				bottomSpacing: 20
			});

			//watch the nav and page for size changes
			values.ro.observe(elements.pageContent);
			values.ro.observe(elements.navContainerInnerInner);
		};

		/**
		* Updates the navigation posiiton.
		* @example
		* module.private.updateNavigation();
		*/
		module.private.updateNavigation = function () {
			if (values.navigation) {
				values.navigation.updateSticky();
			}
		};

		/**
		* Unsticks the navigation removing all styles and event bindings.
		* @example
		* module.private.unStickNavigation();
		*/
		module.private.unStickNavigation = function () {
			if (values.navigation) {
				values.navigation.destroy();
				delete values.navigation;
			}

			values.ro.unobserve(elements.pageContent);
			values.ro.unobserve(elements.navContainerInnerInner);
		};

		//#endregion

		//#region pubsub event handlers

		/**
		* Handle uiAction.NavigationStateChanged setting up or tearing down the sticky navigation.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleUiActionNavigationStateChanged = function (e, data) {
			if (data.isCompact) {
				module.private.unStickNavigation();
			} else {
				module.private.stickNavigation();
			}
		};

		//#endregion

		/**
		* Initiates the module, creates a guid and binds dom events.
		* @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
		* @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
		*/
		module.private.init = function (e, data) {
			var navigationState;

			if (!window.requestAnimationFrame || !Function.prototype.bind) {
				return false;
			}

			//module config
			module.properties.guid = (data && data.guid) ? data.guid : generalUtilities.uuid();

			//get current page state
			elements.leftNavigation = document.querySelector(selectors.leftNavigation);
			navigationState = generalUtilities.getNavigationState();

			//if left navigation exists
			if (elements.leftNavigation) {

				//create resize observer
				values.ro = new ResizeObserver(function (entries, observer) {
					module.private.updateNavigation();
				});

				//find DOM elements
				elements.pageContent = document.querySelector(selectors.pageContent);
				elements.navContainerInnerInner = document.querySelector(selectors.stickySidebarInner);

				//listen for changes in navigationState.isCompact
				pubsub.subscribe('uiAction.NavigationStateChanged', module.private.handleUiActionNavigationStateChanged);

				//if we are not in compact mode
				if (!navigationState.isCompact) {
					module.private.stickNavigation();
				}
			}
		};

		//#region external event bindings

		module.private.init();

		//#endregion

		//expose API
		return module;
	}
);
