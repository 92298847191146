﻿define(['jquery', 'pubsub', 'ajaxUtility', 'mixpanelControl'],

	function ($, pubsub, ajaxUtility) {
		'use strict';


		function updateUserLastActiveDate(e, data) {
			var config;

			config = {
				'method': 'GET',
				'url': '/services/api/contentreach/ping'
			};

			ajaxUtility.publish2(e, data, config);
		}

		pubsub.subscribe('uiHas.UserLastActiveDate', updateUserLastActiveDate);
	}

);