﻿define(['jquery', 'moduleUtilities', 'generalUtilities', 'shareWidgetCompiled'], function shareWidgetControl(
	$,
	moduleUtilities,
	generalUtilities,
	shareWidgetCompiled
) {
	'use strict';
	/**
	 * re_share_widget_control
	 * @exports shareWidgetControl
	 * @author John Allan
	 */
	//#region set up

	var module, selectors, elements, values;

	module = {};
	/** @module */
	module.private = {};
	module.properties = {};
	selectors = {
		body: 'body',
		shareButton: '.js-share',
		shareButtonEtAl: '.js-share, .js-share *',
		shareWidget: '.js-sharewidget, .js-sharewidget *',
		shareWidgetPosition: '.js-sharewidget-position',
		shareWidgetContent: '.js-sharewidget-content',
		shareAllListingsCheckbox: '.js-share-all-listings-checkbox'
	};
	elements = {};
	values = {
		offset: 15 // must be the same as offset defined in _sharewidget.scss
	};

	//#endregion

	//#region utilities

	/**
	 * Determines if a string a valid URL
	 * @param {string} url string to test
	 * @return {boolean}
	 */
	// TODO move to general utilities
	module.private.isURL = function isURL(url) {
		var regexp;
		regexp = /(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[\/?#]\S*)?/i; // jshint ignore:line
		return regexp.test(url);
	};

	/**
	 * Retrieve the data from the DOM e.g. href, contentType
	 * @param {object} el DOM element of anchor which was clicked
	 * @return {object}
	 */
	module.private.getContentData = function getContentData(el) {
		var data;
	
		data = {};
		data.href = el.getAttribute('href');
		data.type = el.getAttribute('data-share-type');
		data.subType = el.getAttribute('data-share-subtype');
		data.sharePage = el.getAttribute('data-share-page');
		data.sharePageOverride = el.getAttribute('data-share-page-override');
		data.showpermalink = el.getAttribute('data-showpermalink') !== "false";
		// available if we want to customize the share message in the future
		// author = el.getAttribute('data-share-author');
		data.facebookAppId = generalUtilities.prop(window, 'RESAAS.Environment.FacebookAppId');
		data.applicationUrl = generalUtilities.prop(window, 'RESAAS.Environment.ApplicationUrl');

		// if the href is not a url assume we are sharing the page
		if (!module.private.isURL(data.href)) {
			data.href = window.location.href;
		}

		return data;
	};

	/**
	 * Build the viewmodel used to render the template.
	 * @param {object} data Content data extracted from the DOM
	 * @return {object}
	 */
	module.private.getViewModel = function getViewModel(data) {
		var d, config, shareAllText, href, username;
		d = $.extend({}, data);
		if (d.sharePageInput) {
			href = window.location.href;
			if (d.sharePageOverride) {
				href = d.sharePageOverride;
			}
			d.encodedHref = encodeURIComponent(href);
		} else {
			href = d.href
			d.encodedHref = encodeURIComponent(href);
		}

		config = {
			facebook: {
				href: generalUtilities.stringFormat(
					'https://www.facebook.com/dialog/share?href={0}&app_id={1}&display=page&redirect_uri=https%3A%2F%2Fwww.facebook.com',
					d.encodedHref,
					d.facebookAppId
				)
			},
			twitter: {
				href: generalUtilities.stringFormat('https://twitter.com/intent/tweet?url={0}', d.encodedHref)
			},
			linkedin: {
				href: generalUtilities.stringFormat(
					'https://www.linkedin.com/shareArticle?url={0}&mini=true&source=RESAAS',
					d.encodedHref
				)
			},
			pinterest: {
				href: generalUtilities.stringFormat('http://pinterest.com/pin/create/button/?url={0}', d.encodedHref)
			},
			email: {
				href: generalUtilities.stringFormat('mailto:?body={0}', d.encodedHref)
			},
			inbox: {
				href: generalUtilities.stringFormat('{0}/inbox?Message={1}', d.applicationUrl, href, d.encodedHref)
			},
			permalink:
				d.href === window.location.href || !d.showpermalink
					? false
					: {
							href: d.href,
							external: d.href.indexOf(d.applicationUrl) === -1
					  },
			content: d
		};

		switch (data.type) {
			case 'post':
				config.pinterest = false;
				break;
			case 'privatepost':
				config.facebook = false;
				config.twitter = false;
				config.linkedin = false;
				config.pinterest = false;
				break;
			case 'publiclead':
				config.pinterest = false;
				break;
			case 'privatelead':
			case 'privatelisting':
				config.facebook = false;
				config.twitter = false;
				config.linkedin = false;
				config.pinterest = false;
				break;
			default:
				break;
		}

		username = document.querySelector('.user-profile-details span[itemprop="name"]');
		if (d.sharePage) {
			if (username) {
				shareAllText = generalUtilities.stringFormat(window.RESAAS.Localization.Listings.USER_SHARE_ALL_LISTINGS_TEXT, username.innerHTML)
			} else {
				shareAllText = window.RESAAS.Localization.Listings.SHARE_ALL_MY_LISTINGS_TEXT;
			}
		}
	

		config.shareAllUsersListingsText = shareAllText;
		config.sharePageInput = d.sharePageInput;
		config.sharePage = d.sharePage;

		return config;
	};

	/**
	 * Determine which position in this order top, right, left, bottom is ideal
	 * @param {object} origin Element to which the content position is relative
	 * @param {object} el Element which is being positioned
	 * @param {object} [viewport=window] Viewport which might cut off the content if not positioned correctly
	 * @return {object}
	 */
	module.private.getIdealPosition = function getIdealPosition(origin, el, viewport) {
		var originRect, elRect, w, top, bottom, left, right, ideal;

		w = viewport || window;
		originRect = origin.getBoundingClientRect();
		elRect = el.getBoundingClientRect();

		top = {
			top: originRect.top - values.offset - elRect.height >= 0,
			bottom: originRect.top - values.offset <= w.innerHeight,
			left: originRect.left - (elRect.width - originRect.width) / 2 >= 0,
			right: originRect.right + (elRect.width - originRect.width) / 2 <= w.innerWidth
		};

		bottom = {
			top: originRect.bottom + values.offset >= 0,
			bottom: originRect.bottom + values.offset + elRect.height <= w.innerHeight,
			left: originRect.left - (elRect.width - originRect.width) / 2 >= 0,
			right: originRect.right + (elRect.width - originRect.width) / 2 <= w.innerWidth
		};

		right = {
			top: originRect.top - (elRect.height - originRect.height) / 2 >= 0,
			bottom: originRect.bottom + (elRect.height - originRect.height) / 2 <= w.innerHeight,
			left: originRect.right + values.offset >= 0,
			right: originRect.right + values.offset + elRect.width <= w.innerWidth
		};

		left = {
			top: originRect.top - (elRect.height - originRect.height) / 2 >= 0,
			bottom: originRect.bottom + (elRect.height - originRect.height) / 2 <= w.innerHeight,
			left: originRect.left - values.offset - elRect.width >= 0,
			right: originRect.left - values.offset <= w.innerWidth
		};

		if (top.top && top.right && top.bottom && top.left) {
			ideal = 'top';
		} else if (right.top && right.right && right.bottom && right.left) {
			ideal = 'right';
		} else if (left.top && left.right && left.bottom && left.left) {
			ideal = 'left';
		} else if (bottom.top && bottom.right && bottom.bottom && bottom.left) {
			ideal = 'bottom';
		} else {
			// TODO Assuming this for the mobile case
			// Could be improved by include the potential vertically scroll in calculations
			// e.g. if no direction is ideal but left is only cut off by the bottom and use can scroll
			// then left is the ideal
			ideal = 'left';
		}

		return ideal;
	};

	//#endregion

	//#region dom event handlers
	/**
	 * Handle click on <body>, opens a share widget or closes them all
	 * @param {object} e Click event
	 */
	module.private.handleBodyClick = function handleClick(e) {
		var contentData,
			anchor;

		if (!e.target.matches(selectors.shareWidget)) {
			$(selectors.shareWidget).remove();
		}

		if (e.target.matches(selectors.shareButtonEtAl)) {
			e.preventDefault();
	
			// TODO polyfill for Element.prototype.closest would mean no jQuery here
			anchor = $(e.target).closest(selectors.shareButton)[0];
			contentData = module.private.getContentData(anchor);

			// render share widget
			module.private.renderShareWidget(contentData, anchor)
		}

	};

	module.private.renderShareWidget = function renderShareWidget(contentData, anchor) {
		var html,
			viewModel,
			idealPosition,
			idealPositionClass,
			widgetParent,
			widgetPosition,
			widgetContent,
			shareAllListingsCheckbox;

		viewModel = module.private.getViewModel(contentData);
		html = shareWidgetCompiled(viewModel);

		anchor.insertAdjacentHTML('afterend', html);
		widgetParent = anchor.parentElement;
		widgetPosition = anchor.nextElementSibling.querySelector(selectors.shareWidgetPosition);
		widgetContent = anchor.nextElementSibling.querySelector(selectors.shareWidgetContent);

		idealPosition = module.private.getIdealPosition(widgetParent, widgetContent);
		idealPositionClass = ['re-sharewidget-position-', idealPosition].join('');

		widgetPosition.classList.add(idealPositionClass);
		shareAllListingsCheckbox = document.querySelector(selectors.shareAllListingsCheckbox);
		if (shareAllListingsCheckbox) {
			shareAllListingsCheckbox.addEventListener('change', function handleChange(event){ module.private.handleSharePageCheckboxClick(event, contentData, anchor) })
		}
	}

	module.private.handleSharePageCheckboxClick = function handleSharePageCheckboxClick(e, contentData, anchor) {
		var shareWidget, data;
		shareWidget = document.querySelector(selectors.shareWidget)
		data = Object.assign({}, contentData);

		if (e.target.checked) {
			data.sharePageInput = true;
		} else {
			data.sharePageInput = false;
		}

		if (shareWidget) {
			$(selectors.shareWidget).remove();
		}

		module.private.renderShareWidget(data, anchor);
	}

	//#endregion

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 */
	module.private.init = function init() {
		// module config
		module.properties.guid = generalUtilities.uuid();

		// polyfill matches()
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.msMatchesSelector;
		}

		// dom event bindings
		document.body.addEventListener('click', module.private.handleBodyClick);
	};

	//#region external event bindings

	module.private.init();

	//#endregion

	// expose API
	return module;
});
