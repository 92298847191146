﻿define([
	'jquery',
	'pubsub',
	'generalUtilities',
	'Handlebars',
	'genericMessageDrawerCompiled',
	'realtimeControl'
], function notificationsDrawerControl($, pubsub, generalUtilities, Handlebars, genericMessageDrawerCompiled) {
	/**
	 * re_notifications_drawer_control
	 * @exports notificationsDrawerControl
	 * @author Eugene Katsov
	 */

	//#region set up

	'use strict';

	var module, selectors, elements, values;

	module = {};
	module.private = {};
	module.properties = {};

	selectors = {
		notificationsContainer: '.re-notifications-drawer',
		notificationsList: '.re-notifications-list',
		notificationItem: '.js-inbox-notification-body',
		notificationClose: '.js-close-item'
	};

	elements = {};
	values = {};

	values.notificationDrawer = [];

	values.config = {};

	values.drawerContainer = [
		'<div class="re-notifications-drawer re-hide-nav-compact">',
		'<ul class="re-notifications-list"></ul>',
		'</div>'
	].join('');

	//#endregion

	//#region utilities

	/**
	 * Add notification event handler method
	 * @param {object} e event
	 * @param {object} data pusher data
	 */
	module.private.handleDataPusher = function handleDataPusher(e, data) {
		if (
			!data ||
			!data.ShowDrawerMessage ||
			generalUtilities.getNavigationState().isCompact ||
			(window.pageType === 'message-inbox' && data.Event === 'NewInstantMessage')
		) {
			return false;
		}

		module.private.createNotification(data);

		return true;
	};

	/**
	 * Get index of obj method
	 * @param {object} obj notification object
	 */
	module.private.getIndexByMsgId = function getIndexByMsgId(obj) {
		var i, index;

		for (i = 0; i < values.notificationDrawer.length; i++) {
			if (values.notificationDrawer[i].NotificationId === obj.NotificationId) {
				index = i;
				break;
			}
		}

		return index >= 0 ? index : null;
	};

	/**
	 * Add notification method
	 * @param {object} obj response data
	 */
	module.private.createNotification = function createNotification(obj) {
		// make NewInstantMessage messaeg data object consistent with generic notiofcation object.
		if (obj.Event === 'NewInstantMessage') {
			obj.Title = obj.SenderFirstName + obj.SenderLastName;
			obj.Body = obj.Message;
			obj.NotificationDeepLink = obj.ConversationDeepLink;
		}

		obj.NotificationDeepLink = generalUtilities.addParamsToUrlQueryString(obj.NotificationDeepLink, {
			notificationId: obj.NotificationId
		});
		obj.MixpanelType = generalUtilities.stringFormat('{0} push notification', obj.Event);

		values.notificationDrawer.unshift(obj);
		module.private.injectNotification(obj);

		module.private.deleteNotification(values.notificationDrawer[values.config.drawerSize]);
		if (values.config.autoDelete) {
			module.private.autoDelete(values.notificationDrawer[0]);
		}
	};

	/**
	 * Inject notification method
	 * @param {object} obj response data
	 */
	module.private.injectNotification = function injectNotification(obj) {
		if (!obj) {
			return false;
		}

		elements.notificationsList.prepend(genericMessageDrawerCompiled(obj));

		return true;
	};

	/**
	 * Delete notification method
	 * @param {object} data notification object
	 */
	module.private.deleteNotification = function deleteNotification(obj) {
		var index;

		if (!obj) {
			return false;
		}

		index = module.private.getIndexByMsgId({ NotificationId: obj.NotificationId });

		if (index == null) {
			return false;
		}

		if (!obj.ConversationId) {
			obj = values.notificationDrawer[index];
		}

		pubsub.publish('uiAction.deleteNotification', obj);

		$(generalUtilities.stringFormat('li[data-notificationid="{0}"]', obj.NotificationId)).fadeOut(500, function () {
			$(this).remove();
		});

		values.notificationDrawer.splice(index, 1);

		return true;
	};

	/**
	 * Autodelete notification method
	 * @param {object} obj notification object
	 */
	module.private.autoDelete = function autoDelete(obj) {
		setTimeout(function deleteNotification() {
			module.private.deleteNotification(obj);
		}, values.config.timeToLive);
	};

	// #endregion

	// #region dom event handlers

	/**
	 * Notification click handler method
	 * @param {object} e event
	 */
	module.private.handleNotificationClick = function handleNotificationClick(e) {
		e.preventDefault();
		generalUtilities.redirect(e.currentTarget.href);
	};

	/**
	 * Notification dismiss click handler method
	 * @param {object} e event
	 */
	module.private.handleNotificationDismiss = function handleNotificationDismiss(e) {
		e.preventDefault();
		module.private.deleteNotification({ NotificationId: e.currentTarget.dataset.notificationid });
	};

	// #endregion

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		// notification object config
		values.config.timeToLive = (data && data.timeToLive) || 12000;
		values.config.drawerSize = (data && data.drawerSize) || 3;
		values.config.container = (data && data.container) || selectors.notificationsList;
		values.config.autoDelete = data && data.autoDelete === false ? false : true;

		// elements
		elements.body = $('body');

		// inject the drawer
		$(elements.body).append(values.drawerContainer);

		// set dependent module values
		elements.notificationsContainer = $(values.config.container);
		elements.notificationsList = $(selectors.notificationsList);

		if (generalUtilities.prop(window, 'RESAAS.User2.UserPusherChannel')) {
			pubsub.publish('uiAction.RSRealtimeSubscribe', {
				channel: window.RESAAS.User2.UserPusherChannel
			});
		}

		// dom event binding
		elements.body.on('click', selectors.notificationItem, module.private.handleNotificationClick);
		elements.body.on('click', selectors.notificationClose, module.private.handleNotificationDismiss);
	};

	// #region external event bindings
	pubsub.subscribe('data.Realtime', module.private.handleDataPusher);

	module.private.init();

	// #endregion

	// expose API
	return module;
});
