﻿define(['jquery', 'pubsub', 'moduleUtilities', 'generalUtilities'], function navigationTreeControl(
	$,
	pubsub,
	moduleUtilities,
	generalUtilities
) {
	/**
	 * re_navigation_tree_control
	 * @exports navigationTreeControl
	 * @author Elina Goldin
	 */
	//#region set up
	'use strict';

	var module, selectors, elements, values;

	module = {};
	module.private = {};
	module.properties = {};
	selectors = {
		navContainer: '.js-nav-container',
		navSecondary: '.re-nav-secondary',
		experienceSwitcher: '.js-experience-switcher',
		notificationItem: '.js-notification',
		navItemOpen: '.re-nav-item-open',
		flyOutMenu: '.js-has-flyoutmenu'
	};
	elements = {};
	values = {};

	values.highlightClass = 're-nav-highlight';

	//#endregion

	//#region pubsub event handlers

	module.private.openSecondaryNavigation = function openSecondaryNavigation(target, animate) {
		if (animate) {
			target
				.addClass('re-nav-item-open')
				.siblings(selectors.navSecondary)
				.removeAttr('aria-hidden')
				.slideDown('fast')
				.parent()
				.addClass('re-nav-open');
		} else {
			target
				.addClass('re-nav-item-open')
				.siblings(selectors.navSecondary)
				.removeAttr('aria-hidden')
				.show()
				.parent()
				.addClass('re-nav-open');
		}
	};

	module.private.closeExperienceSwitcher = function closeExperienceSwitcher(e) {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		elements.experienceSwitcher
			.removeClass('re-nav-item-open')
			.siblings(selectors.navSecondary)
			.attr('aria-hidden', true)
			.hide()
			.parent()
			.removeClass('re-nav-open');
		elements.navContainer.removeClass('re-experienceswitcher-open');
	};

	module.private.closeSecondaryNavigation = function closeSecondaryNavigation(target, animate) {
		if (animate) {
			target
				.removeClass('re-nav-item-open')
				.siblings(selectors.navSecondary)
				.attr('aria-hidden', true)
				.slideUp('fast')
				.parent()
				.removeClass('re-nav-open');
		} else {
			target
				.removeClass('re-nav-item-open')
				.siblings(selectors.navSecondary)
				.attr('aria-hidden', true)
				.hide()
				.parent()
				.removeClass('re-nav-open');
		}
		module.private.closeExperienceSwitcher();
	};

	/**
	 * Handles toggling of secondary navigation.
	 * @param {object} [e] Click event
	 * @param {object} [data] Data passed in by uiAction.NavigationStateChanged event.
	 */
	module.private.handleSecondaryNavigation = function handleSecondaryNavigation(e) {
		var $this;

		$this = $(this);
		// if item has subnav, prevent default on top level link
		if ($this.next(selectors.navSecondary).length > 0) {
			e.preventDefault();
		}

		// handling flyout menus and dropdowns
		if ($this.hasClass('js-has-flyoutmenu')) {
			if ($this.hasClass('re-nav-item-open')) {
				module.private.closeSecondaryNavigation($this, true);
			} else {
				module.private.closeSecondaryNavigation($(selectors.flyOutMenu), false);
				module.private.openSecondaryNavigation($this, false);
				if ($this.hasClass('js-experience-switcher')) {
					elements.navContainer.addClass('re-experienceswitcher-open');
					pubsub.publish('mixpanel.Intent', { type: 'open dialpad' });
				}
			}

			// handling regular left nav subnavs
		} else if ($this.hasClass('re-nav-item-open')) {
			if (!generalUtilities.getNavigationState().isCompact) {
				module.private.handleNavigationClose();
			}
		} else {
			module.private.closeSecondaryNavigation($(selectors.navItemOpen), true);
			module.private.openSecondaryNavigation($this, true);
		}

		return true;
	};

	/**
	 * Closes all secondary navs.
	 */
	module.private.handleNavigationClose = function handleNavigationClose() {
		module.private.closeSecondaryNavigation($(selectors.navItemOpen), false);
	};

	/**
	 * Handle click on <some element>, creates service object and publish pubsub event uiNeeds.FeatureBrowse.
	 * @param {object} e Click event
	 */
	module.private.handleGenericNavClick = function handleGenericNavClick(e) {
		e.stopPropagation();
	};
	//#endregion

	module.private.handleHtmlClick = function handleHtmlClick() {
		module.private.closeSecondaryNavigation($('.js-has-flyoutmenu.re-nav-item-open'), false);
	};

	module.private.handleDownloadAppClick = function handleDownloadAppClick(e) {
		RESAAS.MixPanel.trackEvent('Intent', { type: 'Download App Link', from: 'Top Nav Dropdown' });
	};

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		elements.document = $(document);
		elements.html = $('html');
		elements.window = $(window);
		elements.navItemPrimary = $('.js-nav-item-primary');
		elements.navContainer = $(selectors.navContainer);
		elements.navMobileToggle = $('.js-nav-mobile-toggle');
		elements.navSecondaryExperiences = $('.js-nav-secondary-experiences');
		elements.navDialpad = $('.re-nav-dialpad');
		elements.experienceSwitcher = $(selectors.experienceSwitcher);
		elements.downloadApp = $('.js-nav-item-downloadapp');

		// dom event binding
		elements.html.off('click.NavigationTreeControl');
		elements.navContainer.off('click.NavigationTreeControl');
		elements.navItemPrimary.off('click.NavigationTreeControl');
		elements.document.off('keydown.NavigationTreeControl');
		elements.downloadApp.off('click.NavigationTreeControl');

		elements.navItemPrimary.on('click.NavigationTreeControl', module.private.handleSecondaryNavigation);
		elements.html.on('click.NavigationTreeControl', module.private.handleHtmlClick);
		elements.navContainer.on('click.NavigationTreeControl', module.private.handleGenericNavClick);
		elements.downloadApp.on('click.NavigationTreeControl', module.private.handleDownloadAppClick);

		// this binding now only applies to the experience switcher close button (no others exist)
		elements.navContainer.on(
			'click.NavigationTreeControl',
			'.js-close-nav, .js-nav-back-btn',
			module.private.closeExperienceSwitcher
		);

		elements.document.on('keydown.NavigationTreeControl', function documentOnKeyDown(ev) {
			if (ev.keyCode === 27) {
				module.private.closeExperienceSwitcher();
			}
		});
	};

	//#region external event bindings

	module.private.init();

	//#endregion

	// expose API
	return module;
});
