﻿define([
	'jquery', 'pubsub', 'moduleUtilities', 'generalUtilities', 'notificationsEnumData', 'notificationService', 'messageControl2'],

	function ($, pubsub, moduleUtilities, generalUtilities, notificationsEnumData) {
		/**
		* re_notification_delete_control
		* @exports notificationDeleteControl
		* @author Eugene Katsov
		*/

		//#region set up

		'use strict';

		var module, selectors, elements, values;

		module = {};
		module.private = {};
		module.properties = {};
		selectors = {
			notificationListWrapper: '.re-notifications-list',
			notificationDelete: '.js-generic-delete'
		};
		elements = {};
		values = {};

		//#endregion

		//#region utilities

		/**
		* Delete method
		* @param {object} e event
		* @param {object} data response data
		*/
		module.private.notificationDelete = function (e, data) {
			var requestOptions, request, serviceObject;

			requestOptions = {};

			request = { NotificationId: data.notificationId };

			serviceObject = moduleUtilities.createServiceObject(request, module.properties, requestOptions);

			pubsub.publish('uiHas.NotificationDelete', serviceObject);
		};
		//#endregion

		//#region pubsub event handlers

		/**
		* Delete response error handler
		* @param {object} e event
		* @param {object} data response data
		*/
		module.private.handleDataNotificationDeleteError = function (e, data) {
			pubsub.publish('loggly.error', { message: JSON.stringify(data) });

			pubsub.publish('uiAction.MessageAdd', {
				'message': RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE,
				'type': 'error'
			});
		};

		/**
		* Delete response success handler
		* @param {object} e event
		* @param {object} data response data
		*/
		module.private.handleDataNotificationDeleteSuccess = function (e, data) {
			var $nItem;

			$nItem = elements.notificationListWrapper.find('[data-notificationid="' + data.request.NotificationId + '"]');

			$nItem.fadeOut("slow", function () {
				$nItem.remove();
			});
		};

		/**
		* Handle data event returned from service, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataNotificationDelete = function (e, data) {
			var success;

			success = generalUtilities.prop(data, 'response.HideNotificationForUserResult.Success');

			if (!success) {
				module.private.handleDataNotificationDeleteError(e, data);
			} else {
				module.private.handleDataNotificationDeleteSuccess(e, data);
			}
		};

		//#endregion

		//#region dom event handlers

		/**
		* Handle click on the delete, hides notification list
		* @param {object} e Click event
		*/
		module.private.handleNotificationDeleteClick = function (e) {
			e.preventDefault();
			module.private.notificationDelete('', { notificationId: e.currentTarget.dataset.notificationid });
		};

		//#endregion

		/**
		* Initiates the module, creates a guid and binds dom events.
		* @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
		* @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
		*/
		module.private.init = function (e, data) {
			var html;

			//module config
			module.properties.guid = (data && data.guid) ? data.guid : generalUtilities.uuid();

			//elements
			elements.body = $('body');
			elements.notificationListWrapper = $(selectors.notificationListWrapper);

			//dom event binding
			elements.notificationListWrapper.on('click', selectors.notificationDelete, module.private.handleNotificationDeleteClick);

		};

		//#region external event bindings

		pubsub.subscribe('data.NotificationDelete', module.private.handleDataNotificationDelete);
		pubsub.subscribe('uiAction.NotificationDelete', module.private.notificationDelete);

		module.private.init();

		//#endregion

		//expose API
		return module;
	}
);