﻿define([
	'jquery', 'pubsub', 'moduleUtilities', 'generalUtilities', 'notificationService'],

	function ($, pubsub, moduleUtilities, generalUtilities) {
		'use strict';
		/**
		* re_navigation_unseen_notifications_control
		* @exports navigationUnseenNotificationControl
		* @author May Montazer
		*/
		//#region set up

		var module, selectors, elements;

		module = {};
		/** @module */
		module.private = {};
		module.properties = {};
		selectors = {};
		elements = {};

		//#endregion

		//#region utilities

		/**
		* ask for the unseen notication
		*/
		module.private.notificationUnseenBrowse = function notificationUnseenBrowse() {
			var request, serviceObject;

			request = {};
			serviceObject = moduleUtilities.createServiceObject(request, module.properties);

			// service needs to be using publish2
			pubsub.publish('uiNeeds.NotificationUnseen', serviceObject);
		};

		//#endregion

		/**
		* Handle data event returned from uiNeeds.NotificationUnseen, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataNotificationUnseen = function (e, data) {
			var r = data && data.response;

			if (moduleUtilities.isOwnEvent(data, module.properties) && r) {
				if (r.Connections) {
					var connectionIcon= document.querySelector('.js-notificationcount-connection');
					if (connectionIcon) {
						connectionIcon.innerText = r.Connections;
						document.querySelector('.js-nav-connectioncount').classList.add('re-nav-hasactivity');
					} else {
						pubsub.publish('loggly.error', {
							message: generalUtilities.stringFormat('There is {0} connections to show but the connection icon is not rendered in {1}', r.Connections, document.querySelector('.re-page-navigation, .re-page-navigation-anonymous').innerHTML)
						});
					}
				}
				if (r.Notifications) {
					var notificationIcon = document.querySelector('.js-notificationcount-notification');
					if (notificationIcon){
						notificationIcon.innerText = r.Notifications;
						document.querySelector('.js-nav-notificationcount').classList.add('re-nav-hasactivity');
					} else {
						pubsub.publish('loggly.error', {
							message: generalUtilities.stringFormat('There is {0} notifications but the notification icon is not rendered in {1}', r.Connections, document.querySelector('.re-page-navigation, .re-page-navigation-anonymous').innerHTML)
						});
					}

				}
				if (r.HasNewInstantMessage) {
					document.querySelector('.js-nav-instantmessage-count').classList.add('re-nav-hasactivity');
				}
			} else {
				pubsub.publish('loggly.error', {
					message: generalUtilities.stringFormat('showing unseen notifications is failed {0}', JSON.stringify(data))
				});
			}
		};

		//#endregion


		/**
		* Initiates the module, creates a guid and binds dom events. Tracks notification visits.
		* @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
		* @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
		*/
		module.private.init = function (e, data) {

			if (!generalUtilities.prop(window, 'RESAAS.User2.IsProfessional')) {
				return false;
			}

			//module config
			module.properties.guid = (data && data.guid) ? data.guid : generalUtilities.uuid();
			module.private.notificationUnseenBrowse();
		};

		//#region external event bindings
		pubsub.subscribe('data.NotificationUnseen', module.private.handleDataNotificationUnseen);

		module.private.init();

		//#endregion

		//expose API
		return module;
	}
);
