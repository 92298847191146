﻿define([
	'jquery', 'pubsub', 'moduleUtilities', 'generalUtilities', 'notificationToolbarListCompiled', 'smoke', 'notificationsEnumData', 'relationshiptypesData', 'moment', 'notificationService', 'connectionService', 'messageControl2', 'notificationDeleteControl'],

	function ($, pubsub, moduleUtilities, generalUtilities, notificationToolbarListCompiled, smoke, notificationsEnumData, relationshiptypesData, moment) {
		/**
		* re_notification_connection_control
		* @exports notificationConnectionListControl
		* @author Eugene Katsov
		*/

		//#region set up

		'use strict';

		var module, selectors, elements, values, overLayModule;

		module = {};
		module.private = {};
		module.properties = {};
		selectors = {
			notificationListWrapper: '.re-notifications-list',
			connectionWrapper: '.re-nav-notifications',
			notificationConnect: '.js-notification-connection',
			notificationConnectDismiss: '.js-connection-delete',
			connectionIcon: '.js-connection',
			connectionList: '.js-connection-list',
			notificationCount: '.js-notificationcount'
		};
		elements = {};
		values = {
			requesterRegexObj: /title="(.[^"]+)"/g,
			positionScroll: 0
		};

		//#endregion

		//#region utilities

		/**
		* get notificaiton count count from dom
		* @returns {number} 
		*/
		module.private.getNotificationCountFromDom = function () {
			values.notificationCount = parseInt(elements.connectionList.closest(selectors.connectionWrapper).find(selectors.notificationCount).html(), 10);

			return values.notificationCount;
		};

		/**
		* massage data in irder to prettify date and format count message
		* @param {object} data Data object from service.
		*/
		module.private.massageData = function (data) {
			var regexArr;
			if (Array.isArray(data.Data)) {

				data.Data.map(function (item) {
					//prettify/relativize dates
					if (item.hasOwnProperty('DateCreated')) {
						if (generalUtilities.prop(item, 'DateCreated')) {
							item.RelativeDate = moment(item.DateCreated + '+00:00').fromNow();
						}
					}

					if (item.hasOwnProperty('NotificationType')) {

						item.ActionLink = RESAAS.Localization.Global.ACCEPT;
						item.NotificationTypeName = "connection";
						if (item.hasOwnProperty('Message')) {
							regexArr = values.requesterRegexObj.exec(item.Message);
							item.RequesterName = regexArr === null ? "" : regexArr[1];
						}

					}
				});

				//format count message
				data.notificationCountMessage = (data.Data.length === 1) ? RESAAS.Localization.Global.NOTIFICATIONS_COUNT_MESSAGE_SINGULAR : generalUtilities.stringFormat(RESAAS.Localization.Global.NOTIFCATINOS_COUNT_MESSAGE_PLURAL, data.Data.length);
			}
		};

		/**
		* show and hide activity indicator
		* @param {object} data Data object from service.
		*/
		module.private.showHideActivityIndicator = function (data) {
			var action, notifications;
			notifications = generalUtilities.prop(data, 'response.Data');
			values.notificationCount = generalUtilities.prop(data, 'response.Count');
			action = (notifications && notifications.length) ? 'addClass' : 'removeClass';
			elements.connectionList.parent()[action]('re-nav-hasactivity');
			elements.connectionList.parent().find('.js-notificationcount').html(values.notificationCount);
		};

		/**
		* decrease notification count
		* @param {object} data Data object from service.
		*/
		module.private.decrementActivityIndicator = function () {
			values.notificationCount--;
			elements.connectionList.parent().find('.js-notificationcount').html(values.notificationCount);

			if (values.notificationCount <= 0) {
				elements.connectionList.parent().removeClass('re-nav-hasactivity');
			}
		};
		//#endregion

		//#region pubsub event handlers

		/**
		* Connection response error handler
		* @param {object} e event
		* @param {object} data response data
		*/
		module.private.handleDataNotificationConnectionError = function (e, data) {
			if (moduleUtilities.isOwnEvent(data, module.properties)) {
				pubsub.publish('loggly.error', { message: JSON.stringify(data) });

				pubsub.publish('uiAction.MessageAdd', {
					'message': RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE,
					'type': 'error'
				});
			}
		};

		/**
		* Connection response success handler
		* @param {object} e event
		* @param {object} data response data
		*/
		module.private.handleDataNotificationConnectionSuccess = function (e, data) {
			var successMsg, res, $nItem;

			//decrease count
			module.private.decrementActivityIndicator();

			//if not own event exit
			if (!moduleUtilities.isOwnEvent(data, module.properties)) {
				return false; 
			}

			//hide notification
			res = data.response.SaveResult.Data;

            successMsg = generalUtilities.stringFormat('You’re Connected! <a href="/inbox?UserId={0}">Click here </a> to start a conversation and explore new opportunities together!', res.KnownContactUserId);

			$nItem = elements.notificationListWrapper.find('[data-notificationid="' + data.notificationId + '"]');

			$nItem.fadeOut("slow", function () {
				$nItem.remove();
			});

			pubsub.publish('uiAction.MessageAdd', {
				'message': successMsg,
			});

			pubsub.publish('relationship.accepted', {
				type: 'connection accepted',
				requester: data.requester,
				receiver: data.reciever,
				element: 'notifications',
				content: 'relationship accepted'
			});
		};

		/**
		* Handle data event returned from service, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataNotificationConnection = function (e, data) {
			var success;

			success = generalUtilities.prop(data, 'response.SaveResult.Success');

			if (!success) {
				module.private.handleDataNotificationConnectionError(e, data);
			} else {
				module.private.handleDataNotificationConnectionSuccess(e, data);
			}
		};

		/**
		* Handle data event returned from service, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataConnectionNotificationBrowse = function (e, data) {
			if (moduleUtilities.isOwnEvent(data, module.properties)) {
				if (generalUtilities.prop(data, 'response.Data')) {
					module.private.handleDataConnectionNotificationBrowseSuccess(e, data);
				} else {
					module.private.handleDataConnectionNotificationBrowseError(e, data);
				}
			}
		};

		/**
		* Handle data event returned from service, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataNotificationDelete = function (e, data) {
			if (generalUtilities.prop(data, 'response.HideNotificationForUserResult.Success')) {
				module.private.decrementActivityIndicator();
			}
		};

		/**
		* Handle data event returned from service, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataConnectionNotificationBrowseSuccess = function (e, data) {
			var response;
			response = generalUtilities.prop(data, 'response');
			response.Success = true;

			if (generalUtilities.prop(data, 'response.Count')) {
				module.private.massageData(response);
			}

			response.historyLink = 'connectionrequests';
			response.historyLinkText = RESAAS.Localization.Navigation.VIEW_CONNECTION_HISTORY;
			response.dismissAllowed = true;
			response.NoNotificationMessage = RESAAS.Localization.Navigation.TOOLBAR_NO_NEW_CONNECTION_REQUEST;
            response.showExpandNetworkMessage = true;

            if (response.Data.length > 0) {
                response.Data.forEach(function (notification) {
                    if (generalUtilities.prop(notification, 'ContentMetadata.InvitationMessage')) {
                        var message = notification.ContentMetadata.InvitationMessage;
                        var isLongMessage = message.length > 200;
                        notification.ContentMetadata.truncatedMessage = isLongMessage ? message.substring(0, 200) + '... ' : null;
                    }
                });
            }

			//show/hide the activity indicator (red dot)
			module.private.showHideActivityIndicator(data);

			//inject the notification list
            elements.connectionList.html(notificationToolbarListCompiled(response));


            var seeMoreButtons = document.querySelectorAll('.js-invitation-message-see-more');
            if (seeMoreButtons.length > 0) {
                seeMoreButtons.forEach(function (button) {
                    button.addEventListener('click', function () {
                        var parent = this.closest('.re-notification-invitation-message');
                        var fullMessage = parent.querySelector('.js-invitation-message-full');
                        var truncatedMessage = parent.querySelector('.js-invitation-message-truncated');
                        fullMessage.style.display = 'block';
                        truncatedMessage.style.display = 'none';
                    });
                });
            }

		};

		/**
		* Handles error types and publishes to loggly.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataConnectionNotificationBrowseError = function (e, data) {
			var status, errMsg;

			status = generalUtilities.prop(data, 'response.status');
			switch (status) {
				case '401':
					errMsg = RESAAS.Localization.Global.UNAUTHORIZED_USER_ERROR_MESSAGE;
					break;
				case '500':
					errMsg = RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE
					break;
				default:
					errMsg = RESAAS.Localization.Global.GENERIC_WEBSERVICE_ERROR_MESSAGE
					break;
			}

			pubsub.publish('loggly.error', { 'message': 'connection browse failed to retrieve results: ' + JSON.stringify(data.response) });

			pubsub.publishSync('uiAction.MessageAdd', {
				'message': errMsg,
				'type': 'error'
			});
		};
		//#endregion

		//#region dom event handlers

		/**
		* Connection delete click handler
		* @param {object} e event
		* 
		*/
		module.private.handleNotificationConnectionDeleteClick = function (e) {
			e.preventDefault();
			var requester, reciever, event;
			reciever = [window.RESAAS.User2.FirstName, window.RESAAS.User2.LastName].join(" ");
			requester = e.currentTarget.dataset.requestername;
			event = e;

			smoke.confirm(RESAAS.Localization.Global.SMOKE_HIDE_NOTIFICATION, function (e) {
				if (e) {
					pubsub.publish('relationship.dismissed', {
						type: 'connection dismissed',
						requester: requester,
						receiver: reciever,
						element: 'notifications',
						content: 'relationship dismissed'
					});
					pubsub.publish('uiAction.NotificationDelete', { notificationId: event.target.dataset.notificationid });
				} else {
					return false;
				}
			}, { ok: RESAAS.Localization.Global.HIDE, cancel: RESAAS.Localization.Global.CANCEL_TEXT });
		};

		/**
		* Handle click on the action, connects the users
		* @param {object} e Click event
		*/
		module.private.handleNotificationConnectionClick = function (e) {
			e.preventDefault();

			var requestOptions, request, serviceObject, nId, requester, reciever, cuId;

			reciever = [window.RESAAS.User2.FirstName, window.RESAAS.User2.LastName].join(" ");
			requester = e.currentTarget.dataset.requestername;
			nId = e.currentTarget.dataset.notificationid;
			cuId = e.currentTarget.dataset.creatoruserid;

			requestOptions = {
				requester: requester,
				reciever: reciever,
				notificationId: nId 
			};

			request = {
				contact: {
					KnownContactUserId: cuId,
					Relationships: [{
						Type: relationshiptypesData.browse().Connection,
						Action: 0
					}]
				}
			};

			serviceObject = moduleUtilities.createServiceObject(request, module.properties, requestOptions);

			pubsub.publish('uiHas.ConnectionAdd', serviceObject);
		};

		/**
		* Publish a notification browse request
		*/
		module.private.notificationConnectionBrowse = function () {
			var request, requestOptions, serviceObject, notificationTypes;
			//seen in the request/response but not passed to the webservice (same as module.properties)
			requestOptions = {};
			notificationTypes = notificationsEnumData.browse();
			//sent to webservice
			request = {
				IncludeNotificationTypes: [notificationTypes.InviteToConnectNotification],
				RecordCount: 10
			};

			serviceObject = moduleUtilities.createServiceObject(request, module.properties, requestOptions);

			//service needs to be using publish2
			pubsub.publish('uiNeeds.NotificationBrowse2', serviceObject);
		};

		/**
		* Handle click on the connection icon, hide/shows notification list
		* @param {object} e Click event
		*/
		module.private.handleConnectionIconClick = function (e) {


			pubsub.publish('mixpanel.Intent', {
				type: 'open connection notifications dropdown'
			});

			setTimeout(function () {
				if ($(e.currentTarget).next(selectors.notificationList).is(':visible')) {
					module.private.notificationConnectionBrowse();
				}
			}, 0);
		};

		module.private.handleFindAgentClick = function handleUpsellClick(e) {
			if (generalUtilities.prop(window, 'RESAAS.MixPanel.trackEvent')) {
				e.preventDefault();
				RESAAS.MixPanel.trackEvent(
					'Intent',
					{
						type: e.target.getAttribute('data-mixpanel-type'),
						from: e.target.getAttribute('data-mixpanel-from')
					},
					e.target.getAttribute('href')
				);
			}
		};

		//#endregion

		/**
		* Initiates the module, creates a guid and binds dom events.
		* @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
		* @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
		*/
		module.private.init = function (e, data) {
			var html;

			//module config
			module.properties.guid = (data && data.guid) ? data.guid : generalUtilities.uuid();

			//elements
			elements.body = $('body');
			elements.notificationListWrapper = $(selectors.notificationListWrapper);
			elements.connectionWrapper = $(selectors.connectionWrapper);
			elements.connectionList = $(selectors.connectionList);

			//moment
			generalUtilities.configureMoment(moment);

			//inject pending message
			elements.connectionList.html(notificationToolbarListCompiled({ pendingMessage: true }));

			//get count from dom
			module.private.getNotificationCountFromDom();

			//dom event binding
			elements.notificationListWrapper.on('click', selectors.notificationConnect, module.private.handleNotificationConnectionClick);
			elements.notificationListWrapper.on('click', selectors.notificationConnectDismiss, module.private.handleNotificationConnectionDeleteClick);
			elements.connectionWrapper.on('click', selectors.connectionIcon, module.private.handleConnectionIconClick);
			elements.notificationListWrapper.on('click', '.re-notification-expand-network .re-btn', module.private.handleFindAgentClick);
		};

		//#region external event bindings

		pubsub.subscribe('data.ConnectionAdd', module.private.handleDataNotificationConnection);
		pubsub.subscribe('data.NotificationBrowse2', module.private.handleDataConnectionNotificationBrowse);
		pubsub.subscribe('data.NotificationDelete', module.private.handleDataNotificationDelete);

		module.private.init();

		//#endregion

		//expose API
		return module;
	}
);
