﻿define([
	'jquery',
	'pubsub',
	'moduleUtilities',
	'generalUtilities',
	'missedReblastsService',
	'realtimeControl'
], function missedReblastsControl($, pubsub, moduleUtilities, generalUtilities) {
	/**
	 * re_missed_reblats_control
	 * @exports missedReblastsControl
	 * @author May Montazer
	 */

	//#region set up

	'use strict';

	var module, selectors, elements;

	module = {};
	module.private = {};
	module.properties = {};
	selectors = {
		activityListWrapper: '.re-nav-activity',
		activityPanel: '.js-activity-panel',
		activityLink: '.js-activity-item a[data-id]',
		activityItems: '.js-activity-item',
		newReblastsBtn: '.reblasts-stream-count'
	};
	elements = {};

	//#endregion

	//#region utilities

	/**
	 * Publish new reblast pusher channel
	 */
	module.private.listenPusher = function listenPusher() {
		if (generalUtilities.prop(window, 'RESAAS.Environment.PusherNewReblastChannel')) {
			pubsub.publish('uiAction.RSRealtimeSubscribe', {
				channel: RESAAS.Environment.PusherNewReblastChannel
			});
		}
	};

	/**
	 * update activity panel and indicator numbers base on the new reblsat numbers
	 * @param {object} data reponse from new reblast pusher channel
	 * @param {object} event The pubsub event.
	 */
	module.private.handleDataRealTimeNewReblast = function handleDataRealTimeNewReblast(event, data) {
		var $count, $parent, newReblast;
		if (data.FeedId) {
			$parent = elements.activityLink
				.filter(generalUtilities.stringFormat('[data-id="{0}"]', data.FeedId))
				.parent('.js-activity-item');
			if (!$parent.hasClass('re-secondary-hasactivity')) {
				$parent.addClass('re-secondary-hasactivity');
			}

			$count = elements.activityLink
				.filter(generalUtilities.stringFormat('[data-id="{0}"]', data.FeedId))
				.find('.re-notification');

			$count.each(function countEach() {
				var $self, $text;

				$self = $(this);
				$text = $self.text();

				if ($text.indexOf('+') === -1) {
					if ($text !== '') {
						newReblast = parseInt($text, 10) + 1;
					} else {
						newReblast = 1;
					}
					$self.text(newReblast > 20 ? '20+' : newReblast);
				}
			});

			if (
				!elements.activityListWrapper.hasClass('re-nav-hasactivity') &&
				elements.activityListWrapper.find('.re-secondary-hasactivity').length > 0
			) {
				elements.activityListWrapper.addClass('re-nav-hasactivity');
			}
		}
	};

	/**
	 * Publish a missed reblasts request
	 */
	module.private.missedReblastsBrowse = function missedReblastsBrowse() {
		var request, requestOptions, serviceObject;
		// seen in the request/response but not passed to the webservice (same as module.properties)
		requestOptions = {};

		// sent to webservice
		request = {};
		serviceObject = moduleUtilities.createServiceObject(request, module.properties, requestOptions);

		// service needs to be using publish2
		pubsub.publish('uiNeeds.MissedReblastsBrowse', serviceObject);
	};

	/**
	 * return just reblasts that MissedReblastCount is not zero
	 * @param {object} data reponse from missed reblasts service
	 */
	module.private.filterMissedReblastData = function filterMissedReblastData(data) {
		var dataFilter;

		dataFilter = [];

		if (Array.isArray(data.response)) {
			data.response.forEach(function responseForEach(item, index) {
				if (item.MissedReblastCount !== 0) {
					dataFilter.push(data.response[index]);
				}
			});
		}

		return dataFilter;
	};

	//#endregion

	//#region pubsub event handlers

	/**
	 * Handle data event returned from service, determines success of fail and calls the corresponding function.
	 * @param {object} e The pubsub event.
	 * @param {object} data Data object from service.
	 */
	module.private.handleDataMissedReblasts = function handleDataMissedReblasts(e, data) {
		var success;

		success = generalUtilities.prop(data, 'response');
		if (moduleUtilities.isOwnEvent(data, module.properties)) {
			if (!success) {
				module.private.handleDataMissedReblastsError(e, data);
			} else {
				module.private.handleDataMissedReblastsSuccess(e, data);
			}
		}
	};

	/**
	 * missed reblast success handler - filtering data and render on dom
	 * @param {object} e event.
	 * @param {object} data reponse data.
	 */
	module.private.handleDataMissedReblastsSuccess = function handleDataMissedReblastsSuccess(e, data) {
		var filteredData, $count, $parent;

		filteredData = module.private.filterMissedReblastData(data);

		if (filteredData.length > 0) {
			filteredData.forEach(function filteredDataForEach(item) {
				$parent = elements.activityLink
					.filter(generalUtilities.stringFormat('[data-id="{0}"]', item.FeedId))
					.parent('.js-activity-item');
				$parent.addClass('re-secondary-hasactivity');

				$count = elements.activityLink
					.filter(generalUtilities.stringFormat('[data-id="{0}"]', item.FeedId))
					.find('.re-notification');

				$count.each(function countEach() {
					var $self = $(this);
					// also check if we are over 20 - 20+
					$self.text(item.MissedReblastCount > 20 ? '20+' : item.MissedReblastCount);
				});
			});

			if (elements.activityListWrapper.find('.re-secondary-hasactivity').length > 0) {
				elements.activityListWrapper.addClass('re-nav-hasactivity');
			}
		}
	};

	/**
	 * missed reblast fail handler
	 * @param {object} e event.
	 * @param {object} data reponse data.
	 */
	module.private.handleDataMissedReblastsError = function handleDataMissedReblastsError(e, data) {
		pubsub.publish('loggly.error', {
			message: generalUtilities.stringFormat('getting missed reblast number is failed {0}', JSON.stringify(data))
		});
	};

	/**
	 * Handle click on the activity item, hide the indicator on the ctivity item and red circle on the activity menu
	 * @param {object} e Click event
	 */
	module.private.hideFeedsIndicator = function hideFeedsIndicator(e) {
		var dataId =
			$(this)
				.find('a')
				.attr('data-id') || $('.re-nav-secondary-list .re-nav-item-active').data('id');

		elements.activityLink
			.filter(generalUtilities.stringFormat('[data-id="{0}"]', dataId))
			.find('.re-notification')
			.html('');
		elements.activityLink
			.filter(generalUtilities.stringFormat('[data-id="{0}"]', dataId))
			.parent('.js-activity-item')
			.removeClass('re-secondary-hasactivity');

		if (elements.activityListWrapper.find('.re-secondary-hasactivity').length === 0) {
			elements.activityListWrapper.removeClass('re-nav-hasactivity');
		}
	};

	//#endregion

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {

		// exit if user is not logged in
		if (!generalUtilities.prop(RESAAS, 'User2.UserId')) {
			return false;
		}

		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		pubsub.subscribe('data.MissedReblastsBrowse', module.private.handleDataMissedReblasts);
		pubsub.subscribe('data.Realtime.NewReblast', module.private.handleDataRealTimeNewReblast);

		module.private.missedReblastsBrowse();
		module.private.listenPusher();

		// elements
		elements.body = $('body');
		elements.activityListWrapper = $(selectors.activityListWrapper);
		elements.activityPanel = $(selectors.activityPanel);
		elements.activityLink = $(selectors.activityLink);
		elements.activityItems = $(selectors.activityItems);
		elements.newReblastsBtn = $(selectors.newReblastsBtn);

		// dom event binding
		elements.activityPanel.on('click', '.js-activity-item', module.private.hideFeedsIndicator);
		elements.newReblastsBtn.on('click', module.private.hideFeedsIndicator);

		return true;
    };

    window.addEventListener('load', function () {
        module.private.init();
    });

	//#endregion

	// expose API
	return module;
});
