﻿define(['jquery', 'pubsub', 'moduleUtilities', 'generalUtilities'], function navigationStateControl(
	$,
	pubsub,
	moduleUtilities,
	generalUtilities
) {
	/**
	 * re_navigation_state_control
	 * @exports navigationStateControl
	 * @author John Allan
	 */
	//#region set up
	'use strict';

	var module, selectors, elements, values;

	module = {};
	module.private = {};
	module.properties = {};
	selectors = {};
	elements = {};
	values = {};

	selectors.html = 'html';
	selectors.nav = '.js-nav-container';
	selectors.navItem = '.js-nav-item';
	selectors.primaryNavListItem = '.js-nav-list-item-primary';
	selectors.footer = '.re-page-footer';

	values.activeClass = 're-nav-item-active';
	values.highlightClass = 're-nav-highlight';
	values.openClass = 're-nav-item-open';

	//#endregion

	//#region utilities

	/**
	 * Publishes an updated navigation state if there is a change
	 */
	module.private.publishState = function publishState(force) {
		var currentState, currentStateString;
		currentState = generalUtilities.getNavigationState();
		currentStateString = JSON.stringify(currentState);

		if (force === true || !values.previousStateString || values.previousStateString !== currentStateString) {
			values.previousStateString = currentStateString;
			pubsub.publish('uiAction.NavigationStateChanged', currentState);
		}
	};

	/**
	 * selects a nav item based on url
	 * @returns {object} containing a $primary and $secondary navigation node
	 */
	module.private.urlToNavSelectors = function urlToNavSelectors(domainUrl, pathname, hash, search) {
		var result, dashboard, domHash, activeTab, pathSegment, regexResult, currentReblastTab, urlQuery, groupUniquName, decodedUrl;

		result = {};
		dashboard = ['[href="', domainUrl, '/dashboard"]'].join('');

		// set the default selectors
		result.secondary = ['[href="', pathname.replace(/\/$/, ''), hash, '"]'].join('');

		// set page specific primary and secondary selectors
		switch (window.pageType) {
			case 'profile':
				result.primary = '[data-id="activity"]';

				// find the currently selected tab in the old nav
				activeTab = document.querySelector('.tab.active a');
				domHash = activeTab ? activeTab.hash : false;
				urlQuery = generalUtilities.queryStringToObject(search);

				if (urlQuery && urlQuery.ReturnUrl) {
					decodedUrl = decodeURIComponent(urlQuery.ReturnUrl);
				}
				
				groupUniquName = pathname.indexOf('/feed/group') > -1 ? pathname.split("/feed/group/")[1] : false;

				if (domHash) {
					result.secondary = '[href$="/feed/group' + domHash.split('#')[1] + '"],[href$="' + hash + '"]';
				} else if (hash) {
					result.secondary = '[href$="/feed/group' + hash.split('#')[1] + '"],[href$="' + hash + '"]';
				} else if (decodedUrl && decodedUrl.indexOf('/feed/group') > -1) {
					// returnurl with group feed link
					result.secondary = '[href$="/feed/group/' + decodedUrl.split("/feed/group/")[1] + '"]';
				} else if (groupUniquName) {
					result.secondary = '[href$="/feed/group/' + groupUniquName + '"]';
				} else if (pathname === '/feed/local') {
					result.secondary = '[href$="/feed/local"]';
				} else if (pathname === '/feed/myposts') {
					result.secondary = '[href$="/feed/myposts"]';
				} else if (pathname === '/feed/connections') {
					result.secondary = '[href$="/feed/connections"]';
				} else if (pathname === '/feed/global') {
					result.secondary = '[href$="/feed/global"]';
				} else if (!hash && !search) {
					if (RESAAS.Environment.DefaultGroupUniqueName) {
						currentReblastTab = RESAAS.Environment.DefaultGroupUniqueName;
						// special case if currentReblastTab is realtimemls switch to agentfirst
						if ('realtimemls' === currentReblastTab) {
							currentReblastTab = 'agentfirst';
						}
						result.secondary = '[href$="/feed/group/' + currentReblastTab + '"]';
					} else {
						result.secondary = '[href$="/feed/global"]';
					}
				}

				break;
			case 'activity-feed':
				if (window.isO === 'False') {
					result.primary = '[data-id="agents"]';
					result.secondary = null;
				}

				if (window.isO === 'True') {
					result.primary = '[data-id="settings"]';
					result.secondary = '[href="' + domainUrl + '/profile"]';
				}
				break;
			case 'send-message':
				result.primary = '[data-id="agents"]';
				result.secondary = null;
				break;
			case 'public-question':
				if (window.RESAAS.User2.IsProfessional) {
					result.primary = '[data-id="learn"]';
				} else {
					result.primary = '[data-id="questions"]';
				}
				result.secondary = null;
				break;
			case 'hashtag':
				result.primary = '[data-id="activity"]';
				result.secondary = null;
				break;
			case 'group':
				result.primary = '[data-id="activity"]';
				regexResult = pathname.match(
					/(\/groups\/)([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i
				);
				result.secondary = regexResult ? '[data-id="' + regexResult[2] + '"]' : null;
				break;
			case 'dashboard':
				result.primary = '[data-id="settings"]';
				result.secondary = dashboard;
				break;
			case 'manageSubscriptions':
				result.primary = '[data-id="settings"]';
				result.secondary = dashboard;
				break;
			case 'listing-discovery':
				result.primary = '[data-id="listings"]';
				result.secondary = '[href^="/listings"]';
				break;
			case 'user-discovery':
				if (pathname.indexOf('brokeros') >= 0) {
					result.primary = '[data-id="brokeros"]';
					result.secondary = null;
				} else if (search.indexOf('RelationshipSearch=2') >= 0) {
					result.primary = '[data-id="agents"]';
					result.secondary = '[href^="/agents?RelationshipSearch=2"]';
				} else {
					result.primary = '[data-id="agents"]';
					result.secondary = '[href="/agents"]';
				}
				break;
			case 'my-referrals':
				result.primary = '[data-id="referrals"]';
				// result.secondary = will be found by exact match (my leads)
				break;
			case 'one-to-one-referral-form':
				result.primary = '[data-id="referrals"]';
				result.secondary = null;
				break;
			case 'lead-create':
				if (search.indexOf('referral') >= 0) {
					result.primary = '[data-id="referrals"]';
					result.secondary = '[href^="/ReferralCreate"]';
				} else if (search.indexOf('buyerneed') >= 0) {
					result.primary = '[data-id="buyerNeeds"]';
					result.secondary = '[href^="/BuyerNeedCreate"]';
				} else if (search.indexOf('instant') >= 0) {
					result.primary = '[data-id="instantLeads"]';
					result.secondary = '[href^="/InstantLeadCreate"]';
				}
				break;
			case 'DiscoverLeadsPage':
				if (search.indexOf('2') >= 0) {
					result.primary = '[data-id="referrals"]';
					result.secondary = '[href="/leads?leadTypeFilter=2"]';
				} else if (search.indexOf('3') >= 0) {
					result.primary = '[data-id="buyerNeeds"]';
					result.secondary = '[href="/leads?leadTypeFilter=3"]';
				} else if (search.indexOf('4') >= 0) {
					result.primary = '[data-id="instantLeads"]';
					result.secondary = '[href="/leads?leadTypeFilter=4"]';
				} 
				break;
			case 'listing-edit':
				result.primary = '[data-id="listings"]';
				result.secondary = null;
				break;
			case 'referral-response':
				if (window.RESAAS.ReferralDetails.ReferralType === 3) {
					result.primary = '[data-id="buyerNeeds"]';
				}
				if (window.RESAAS.ReferralDetails.ReferralType === 2) {
					result.primary = '[data-id="referrals"]';
				}
				result.secondary = null;
				break;
			case 'instant-referral-response':
				result.primary = '[data-id="instantLeads"]';
				break;
			case 'referral-app':
				result.primary = '[data-id="referrals"]';
				break;
			case 'buyerneeds-app':
				result.primary = '[data-id="buyerNeeds"]';
				break;
			case 'listing-detail':
				result.primary = '[data-id="listings"]';
				result.secondary = null;
				break;
			case 'reblast-detail':
				result.primary = '[data-id="activity"]';
				result.secondary = null;
				break;
			case 'question-detail':
				result.primary = '[data-id="learn"]';
				result.secondary = null;
				break;
			case 'brokeros':
				result.primary = '[data-id="brokeros"]';
				result.secondary = '[href^="/brokeros"]';
				break;
			case 'brokeros-clients':
				result.primary = '[data-id="brokeros"]';
				result.secondary = null;
				break;
			case 'dashboardAnalytics':
				if (search.indexOf('type=1') >= 0) {
					result.primary = '[data-id="analytics"]';
					result.secondary = '[href="/analytics?type=1"]';
				} else if (search.indexOf('type=7') >= 0) {
					result.primary = '[data-id="analytics"]';
					result.secondary = '[href="/analytics?type=7"]';
				} else {
					result.primary = '[data-id="analytics"]';
					result.secondary = '[href="/analytics?type=1"]';
				}
				break;
			default:
				break;
		}

		return result;
	};

	/**
	 * Highlight the corresponding nav item in the nav according to the current page
	 */
	module.private.setCurrentPage = function setCurrentPage() {

		var activeClasses, domainUrl, currentSelectors, $selected;

		$selected = $();

		// add back once groups are removed from experience switcher
		// highlightClasses = values.activeClass + ' ' + values.highlightClass + ' ' + values.openClass;

		// remove once groups are removed from experience switcher
		activeClasses = values.highlightClass + ' ' + values.openClass;

		// cleanup any existing highlighting
		elements.allLinks.removeClass(values.activeClass);

		// get nav selectors
		domainUrl = generalUtilities.prop(RESAAS, 'User2.DomainUrl');
		currentSelectors = module.private.urlToNavSelectors(
			domainUrl,
			window.location.pathname,
			window.location.hash,
			window.location.search
		);

		// find secondary nav item
		if (currentSelectors.secondary) {
			$selected = $selected.add(elements.allLinks.filter(currentSelectors.secondary));
		}

		// add primary nav item
		if ($selected.length) {
			$selected = $selected.add($selected.closest(selectors.primaryNavListItem).children(selectors.navItem));
		} else {
			$selected = $selected.add(elements.allLinks.filter(currentSelectors.primary));
		}

		// highlight nav
		// add back once groups are removed from experience switcher
		// $selected.addClass(highlightClasses);

		// remove once groups are removed from experience switcher
		$selected
			.addClass(values.activeClass)
			.not('.js-has-flyoutmenu')
			.addClass(activeClasses)
			.siblings('.js-nav-secondary')
			.removeAttr('aria-hidden')
			.slideDown('fast')
			.parent()
			.addClass('re-nav-open');
	};

	//#endregion

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		elements.window = $(window);
		elements.html = $(selectors.html);
		elements.nav = $(selectors.nav);
		elements.footer = $(selectors.footer);
		elements.allLinks = elements.nav.find(selectors.navItem);

		module.private.publishState(true);
		module.private.setCurrentPage();

		window.addEventListener('reactNavigate', module.private.setCurrentPage);

		// dom event binding
		elements.window
			.off('resize.NavigationStateControl')
			.on('resize.NavigationStateControl', generalUtilities.debounce(module.private.publishState, 100));
		elements.window
			.off('popstate.NavigationStateControl hashchange.NavigationStateControl')
			.on(
				'popstate.NavigationStateControl hashchange.NavigationStateControl',
				generalUtilities.debounce(module.private.setCurrentPage, 100)
			);
	};

	//#region external event bindings
	pubsub.subscribe('uiAction.NavigationStateControlInit', module.private.init);

	//#endregion

	//expose API
	return module;
});
