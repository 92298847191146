﻿/**
 * re_notificationsenum_data
 * @module notificationsEnumData
 * @author Eugene Katsov
 */

define([],

	function () {
		'use strict';

		var data = {};
		data.RegularUserUpgradeNotification = 0;
		data.InviteToSellingCommunityNotification = 1;
		data.GeneralTextNotification = 2;
		data.GeneralHtmlNotification = 3;
		data.InviteToConnectNotification = 4;
		data.ReblastCommentNotification = 5;
		
		var module = {
			browse: function () {
				return data;
			}
		};

		return module;
	}
);