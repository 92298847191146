﻿define(['pubsub', 'ajaxUtility', 'generalUtilities'],

	function notificationService(pubsub, ajaxUtility, generalUtilities) {

		function uiNeedsNotificationBrowse(e, data) {
			var config;

			config = {
				'method': 'GET',
				'url': '/Services/MainService.svc/json/GetNotificationsForUser'
			};

			ajaxUtility.publish2(e, data, config);
		}


		function uiNeedsNotificationBrowse2(e, data) {
			var config;

			config = {
				'method': 'GET',
				'url': '/Services/api/notifications'
			};

			ajaxUtility.publish2(e, data, config);
		}

		function uiHasNotificationDelete(e, data) {
			var config;

			config = {
				'method': 'POST',
				'url': '/Services/MainService.svc/json/HideNotificationForUser'
			};

			ajaxUtility.publish2(e, data, config);

		}

		function uiHasNotificationVisit(e, data) {
			var config;

			config = {
				'method': 'POST',
				'url': '/Services/api/notifications/visit'
			};

			ajaxUtility.publish2(e, data, config);

		}

		function uiNeedsNotificationUnseen(e, data) {
			var config;

			config = {
				'method': 'GET',
				'url': '/Services/api/notifications/unseen'
			};

			ajaxUtility.publish2(e, data, config);

		}

		function uiHasNotificationWebForm(e, data) {
			var config, reason;
			
			config = {
				'method': 'POST',
				'url': '/Services/api/notifications/webformcomplete?type=1'
			};
			reason = generalUtilities.prop(data, 'request.reason');

			if (reason === 'instantref_success') {
				config.url = '/Services/api/notifications/webformcomplete?type=2';
			}
			else if (reason === 'instantref_cancel') {
				config.url = '/Services/api/notifications/webformcomplete?type=3';
			}


			ajaxUtility.publish2(e, data, config);
		}

		function uiHasNotificationPhoneView(e, data) {
			var config;

			config = {
				'method': 'POST',
				'url': '/Services/api/notifications/phoneview'
			};

			ajaxUtility.publish2(e, data, config);

		}

		pubsub.subscribe('uiNeeds.NotificationBrowse', uiNeedsNotificationBrowse);
		pubsub.subscribe('uiNeeds.NotificationBrowse2', uiNeedsNotificationBrowse2);
		pubsub.subscribe('uiHas.NotificationDelete', uiHasNotificationDelete);
		pubsub.subscribe('uiHas.NotificationVisit', uiHasNotificationVisit);
		pubsub.subscribe('uiHas.NotificationWebForm', uiHasNotificationWebForm);
		pubsub.subscribe('uiNeeds.NotificationUnseen', uiNeedsNotificationUnseen);
		pubsub.subscribe('uiNeeds.NotificationPhoneView', uiHasNotificationPhoneView);
	}

);
