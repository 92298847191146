﻿define(['pubsub', 'ajaxUtility'],

	function (pubsub, ajaxUtility) {
		'use strict';

		function uiNeedsMissedReblasts(e, data) {
			var config;
			config = {
				'method': 'GET',
				'url': '/Services/Api/MissedReblasts',
			};

			ajaxUtility.publish2(e, data, config);
		}

		pubsub.subscribe('uiNeeds.MissedReblastsBrowse', uiNeedsMissedReblasts);

	}

);