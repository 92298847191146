﻿/**
 * @fileOverview re_navigation_control.js
 *
 * @author John Allan
 *
 */
define([
    'pubsub',
    'generalUtilities',
    'inboxNotificationControl',
    'navigationStateControl',
    'notificationToolbarGenericListControl',
    'notificationConnectionListControl',
    'notificationVisitControl',
    'navigationTreeControl',
    'navigationMobileControl',
    'navigationDialpadControl',
    'navigationMissedReblastControl',
    'navigationExperienceControl',
    'navigationScrollControl',
    'navigationInviteControl',
    'toolbarControl',
    'userLastActiveDateControl',
    'navigationUnseenNotificationControl'
], function navigationControl(pubsub, generalUtilities) {
    'use strict';

    var module = {};
    var elements = {};
    var selectors = {};
    var values = {};

    module.CONTROL_ID = generalUtilities.uuid();
    module.private = {};

    selectors.navContainer = '.js-nav-container';
    selectors.feedApp = '#FeedApp';
    selectors.groupCreate = '.js-group-create';

    values.groupCreate = 'groupCreate';

    module.private.handleGroupCreateClick = function handleGroupCreateClick(e) {
        if (!elements.feedApp.length) {
            if (Modernizr.localstorage ) {
                localStorage.setItem(values.groupCreate, values.groupCreate);
            }
            generalUtilities.redirect('/');
        }
    };

    module.private.init = function(e, data) {
        elements.navContainer = $(selectors.navContainer);
        elements.feedApp = $(selectors.feedApp);

        elements.navContainer.on('click', selectors.groupCreate, module.private.handleGroupCreateClick);

        pubsub.publish('uiAction.NavigationStateControlInit');
    };

    module.private.init();

    return module;
});
