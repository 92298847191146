﻿define(['pubsub', 'ajaxUtility'],

	function (pubsub, ajaxUtility) {
		'use strict';

		function uiHasConnectionAdd(e, data) {
			var config;

			config = {
				'method': 'POST',
				'url': '/Services/ContactService.svc/json/Save'
			};

			ajaxUtility.publish2(e, data, config);
		}

		function uiNeedsConnectionBrowse(e, data) {
			var config;
			config = {
				'method': 'GET',
				'url': '/Services/api/contacts/relationships'
			};

			ajaxUtility.publish2(e, data, config);
		}

		pubsub.subscribe('uiHas.ConnectionAdd', uiHasConnectionAdd);
		pubsub.subscribe('uiNeeds.ConnectionBrowse', uiNeedsConnectionBrowse);
	}

);