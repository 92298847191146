﻿define(['jquery', 'pubsub', 'generalUtilities', 'realtimeControl'], function inboxNotificationControl(
	$,
	pubsub,
	generalUtilities
) {
	/**
	 * re_inbox_notification_control
	 * @exports inboxNotificationControl
	 * @author Eugene Katsov
	 */

	//#region set up

	'use strict';

	var module, selectors;

	module = {};
	module.private = {};
	module.properties = {};

	selectors = {
		navInboxContainer: '.re-nav-inbox',
		inboxRedDot: '.js-notificationcount-inbox'
	};

	//#endregion

	//#region utilities

	/**
	 * Animate the red dot to alert the user
	 * Leave a red dot behind after animation
	 */
	module.private.animateRedDot = function animateRedDot() {
		if (!$(selectors.navInboxContainer).hasClass('re-nav-hasactivity')) {
			$(selectors.navInboxContainer).addClass('re-nav-hasactivity');
		}

		$(selectors.inboxRedDot).addClass('sonar-wave');

		setTimeout(function animateRedDotTimeout() {
			$(selectors.inboxRedDot).removeClass('sonar-wave');
		}, 1500);
	};

	//#endregion

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		if (window.pageType === 'message-inbox') {
			return false;
		}

		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		if (generalUtilities.prop(window, 'RESAAS.User2.UserPusherChannel')) {
			pubsub.publish('uiAction.RSRealtimeSubscribe', {
				channel: window.RESAAS.User2.UserPusherChannel
			});
		}

		pubsub.subscribe('data.Realtime.NewInstantMessage', module.private.animateRedDot);

		return true;
	};

	//#region external event bindings
	module.private.init();

	//#endregion

	// expose API
	return module;
});
