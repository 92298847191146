﻿/**
 * @fileOverview re_userlastactivedate_control.js
 *
 * @author May Montazer
 *
 * @events
 * Subscribe
 * 'uiHas.UserLastActiveDate
 */

define(['jquery', 'pubsub', 'generalUtilities', 'updateUserLastActiveDateService'], function(
    $,
    pubsub,
    generalUtilities
) {
    'use strict';

    var module;

    module = {};
    module.private = {};
    module.properties = {};

    //#endregion

    //#region utilities

    /**
     * set the UserLastActiveTime session storage .
     * @param {object} data The success status data.
     */
    module.private.handleUserLastActiveDateSuccess = function() {
        if (window.Modernizr && window.Modernizr.sessionstorage) {
            sessionStorage.setItem('UserLastActiveTime', new Date().getTime());
        }
    };

    /**
     * Handle error
     * @param {object} e The pubsub event.
     * @param {object} data The UserrrLastActiveDate data.
     */
    module.private.handleUserLastActiveDateError = function(e, data) {
        pubsub.publish('loggly.error', {
            message: generalUtilities.stringFormat('User Last Active Date update is failed {0}', JSON.stringify(data))
        });
    };

    /**
     * handle data from contentreach service
     * @param {object} data The UserLastActiveTime data.
     */
    module.private.handleUserLastActiveDate = function(e, data) {
        if (generalUtilities.prop(data, 'response.status') && data.response.status === 200) {
            module.private.handleUserLastActiveDateSuccess();
        } else {
            module.private.handleUserLastActiveDateError(e, data);
        }
    };

    /**
     * update the user last active date.
     */
    module.private.updateUserLastActiveDate = function() {
        var fiveMinCheck;

        if (window.Modernizr && window.Modernizr.sessionstorage && sessionStorage.getItem('UserLastActiveTime')) {
            fiveMinCheck = new Date().getTime() - sessionStorage.getItem('UserLastActiveTime');

            // checking 5 min is over
            if (fiveMinCheck > 300000) {
                pubsub.publish('uiHas.UserLastActiveDate', {});
            }
        } else {
            pubsub.publish('uiHas.UserLastActiveDate', {});
        }
    };

    //#endregion

    /**
     * Initiates the module, creates a guid and binds dom events.
     * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
     * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
     */
    module.private.init = function() {
        if (generalUtilities.prop(window, 'RESAAS.User2.UserId')) {
            module.private.updateUserLastActiveDate();
        } else {
            return false;
        }
    };

    //#region external event bindings

    module.private.init();

    pubsub.subscribe('data.UserLastActiveDate', module.private.handleUserLastActiveDate);

    //#endregion

    //expose API
    return module;
});
