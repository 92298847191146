﻿define([
	'jquery', 'pubsub', 'moduleUtilities', 'generalUtilities', 'notificationService'],

	function ($, pubsub, moduleUtilities, generalUtilities) {
		'use strict';
		/**
		* re_notification_visit_control
		* @exports notificationVisitControl
		* @author John Allan
		*/
		//#region set up

		var module, selectors, elements, values;

		module = {};
		/** @module */
		module.private = {};
		module.properties = {};
		selectors = {};
		elements = {};
		values = {};

		selectors.notificationListWrappers = '.js-notificationhistory-list, .js-notifications-list';

		//#endregion

		//#region utilities

		/**
		* Called on init to mark a notification as visted.
		*/
		module.private.markNotificationAsVisited = function () {
			var authenticated, notificationId, request, serviceObject;

			authenticated = !!generalUtilities.prop(RESAAS, 'User2.UserId');
			notificationId = generalUtilities.queryStringToObject().notificationId;
			
			if (authenticated && notificationId) {
				request = {
					notificationId: notificationId
				};

				serviceObject = moduleUtilities.createServiceObject(request, module.properties);

				pubsub.publish('uiHas.NotificationVisit', serviceObject);
			}
		};

		//#endregion

		//#region pubsub event handlers

		/**
		* Handles error for the data.NotificationVisit event.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataNotificationVisitError = function (e, data) {
			pubsub.publish('loggly.error', { message: 'Setting notification to visited failed: ' + JSON.stringify(data) });
		};

		/**
		* Handle data event returned from uiHas.NotificationVisit, determines success of fail and calls the corresponding function.
		* @param {object} e The pubsub event.
		* @param {object} data Data object from service.
		*/
		module.private.handleDataNotificationVisit = function (e, data) {
			if (moduleUtilities.isOwnEvent(data, module.properties)) {
				if (data.response.status !== 200) {
					module.private.handleDataNotificationVisitError(e, data);
				}
			}
		};

		//#endregion

		//#region dom event handlers
		/**
		* Handle click on notification list wrappers, adds the notificationId to the url before redirecting.
		* @param {object} e Click event
		*/
		module.private.handleClick = function (e) {
			var href, url, $notification, notificationId, visited, queryObject;

			$notification = $(e.target).closest('[data-notificationid]');
			visited = $notification.hasClass('js-notification-visited');
			notificationId = $notification.attr('data-notificationid');

			if (notificationId) {
				e.preventDefault();

				href = $(e.target).attr('href');
				queryObject = {
					mp_from: 'notification'
				};

				if (!visited) {
					queryObject.notificationId = notificationId;
				}

				url = generalUtilities.addParamsToUrlQueryString(href, queryObject);
				generalUtilities.redirect(url);
			}
		};

		//#endregion

		/**
		* Initiates the module, creates a guid and binds dom events. Tracks notification visits.
		* @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
		* @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
		*/
		module.private.init = function (e, data) {

			//module config
			module.properties.guid = (data && data.guid) ? data.guid : generalUtilities.uuid();
			elements.notificationListWrappers = $(selectors.notificationListWrappers);

			//dom event binding
			elements.notificationListWrappers.off('click.notificationVisit').on('click.notificationVisit', 'a', module.private.handleClick);

			//mark notification as visited
			module.private.markNotificationAsVisited();
		};

		//#region external event bindings
		pubsub.subscribe('data.NotificationVisit', module.private.handleDataNotificationVisit);

		module.private.init();

		//#endregion

		//expose API
		return module;
	}
);