﻿define([
	'jquery', 'pubsub', 'moduleUtilities', 'generalUtilities'],

	function ($, pubsub, moduleUtilities, generalUtilities) {
		/**
		* re_navigation_experience_control
		* @exports navigationExperienceControl
		* @author Sylvia Wang
		*/
		//#region set up
		'use strict';

		var module, selectors, elements;

		module = {};
		module.private = {};
		module.properties = {};
		selectors = {
			experiencesSubnav: '.js-nav-secondary-experiences',
			navContainer: '.js-nav-container',
			experienceItem: '.js-nav-item[data-targetexperience]',
			experienceNotification: '.js-nav-item[data-targetexperience] .re-notification'
		};
		elements = {};

		//#endregion


		//#region pubsub event handlers

		/**
		* handles experience switching
		* @param {object} [e] the event
		*/
		module.private.handleExperienceClick = function (e) {
			var href, arr, guid, reblastUrl, selector, isInternalLink, link, domainUrl, isActivityLink, targetExperience, currentExperience, redirectPageCB;

			href = e.currentTarget.href;
			arr = href.split('/');
			guid = arr[arr.length - 2]; // will only be a GUID when joining REMAX QA group if not already a members
			reblastUrl = arr[arr.length - 1];
			link = generalUtilities.prop(RESAAS, 'Environment.ApplicationUrl');
			domainUrl = generalUtilities.prop(RESAAS, 'User2.DomainUrl');

			targetExperience = $(e.currentTarget).data().targetexperience;
			currentExperience = $(selectors.experiencesSubnav).data('currentexperience');

			// If reblast URL contains a = (ex: query string), then we'll grab what comes after it. Otherwise, grab the whole URL.
			selector = (reblastUrl.indexOf('=') > -1) ? reblastUrl.split('=')[1] : reblastUrl;

			// If the target hostname is the same as the current hostname, OR if there is no hostname (indicating a target on the same site)
			isInternalLink = location.hostname === e.currentTarget.hostname || !e.currentTarget.hostname.length;
			isActivityLink = (href === link + '/') || (href.indexOf(link + domainUrl + '#') === 0) || (href.indexOf('/join?group') > -1);

			if (isInternalLink) {
				generalUtilities.setCookie("resaasexperience", targetExperience);
			}

			redirectPageCB = function () {
				//reloads page for activities links
				if (isInternalLink && isActivityLink) {
					window.scrollTo(0, 0);
					window.history.pushState('', '', href);
					generalUtilities.reload(true);
				}
				// redirects otherwise
				else {
					generalUtilities.redirect(href);
				}
			};

			e.preventDefault();
			if (targetExperience !== currentExperience) {
				pubsub.publish('mixpanel.Intent', { type: 'click experience, target-experience = ' + targetExperience });
				setTimeout(function () {
					redirectPageCB();
				}, 250);
			}
			else {
				redirectPageCB();
			}
		};

		/**
		* Initiates the module, creates a guid and binds dom events.
		* @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
		* @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
		*/
		module.private.init = function (e, data) {
			var currentExperience;

			//module config
			elements.navContainer = $(selectors.navContainer);

			module.properties.guid = (data && data.guid) ? data.guid : generalUtilities.uuid();

			// marking current experience in the switcher
			currentExperience = $(selectors.experiencesSubnav).data('currentexperience');
			$(selectors.experienceItem + '[data-targetexperience="' + currentExperience + '"]').addClass('re-current-experience');

			elements.navContainer.off('click.navigationExperienceControl').on('click.navigationExperienceControl', selectors.experienceItem, selectors.experienceNotification, module.private.handleExperienceClick);

		};

		//#region external event bindings

		module.private.init();

		//#endregion

		//expose API
		return module;
	}
);
