﻿/**
 * re_relationshiptypes_data
 * @module relationshiptypesData
 * @author Eugene Katsov
 */

define([],

	function () {
		'use strict';

		var data = {};
		data.Following = 0;
		data.Connection = 1;
		data.SellingCommunity = 2;
		data.Office = 3;
		data.Firm = 4;
		data.RealEstateBoard = 5;

		var module = {
			browse: function () {
				return data;
			}
		};

		return module;
	}
);