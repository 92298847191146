﻿define(['Handlebars', 'text!notificationToolbarListTemplate'],

	function (Handlebars, notificationToolbarListTemplate) {
		'use strict';

		var h = Handlebars.compile(notificationToolbarListTemplate);

		return h; 
	}

);