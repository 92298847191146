﻿define(['jquery', 'pubsub', 'moduleUtilities', 'generalUtilities'], function navigationMobileControl(
	$,
	pubsub,
	moduleUtilities,
	generalUtilities
) {
	/**
	 * re_navigation_mobile_control
	 * @exports navigationMobileControl
	 * @author John Allan
	 */
	//#region set up
	'use strict';

	var module, selectors, elements, values;

	module = {};
	module.private = {};
	module.properties = {};
	selectors = {
		navMobile: '.js-nav-mobile',
		navPrimary: '.re-nav-primary',
		navSecondary: '.re-nav-secondary',
		mobileNavToggle: '.js-nav-mobile-toggle',
		activityItem: '.js-activity-item > a, .js-group-create'
	};
	elements = {};
	values = {
		positionScroll: 0,
		navOpenClass: 're-nav-open',
		navItemOpenClass: 're-nav-item-open',
	};

	//#endregion

	//#region pubsub event handlers

	module.private.closeMobileMenu = function closeMobileMenu(skipScrollReset) {
		// style ui
		$([selectors.mobileNavToggle, selectors.navMobile].join()).removeClass(values.navOpenClass);

		// update page layout
		elements.html.removeClass('re-compact-overflow-y-hidden');

		if (!skipScrollReset) {
			elements.window.scrollTop(values.positionScroll);
		}
	};

	module.private.openMobileMenu = function openMobileMenu() {
		// close any old menus
		$('.re-nav-item-open')
			.removeClass(values.navItemOpenClass)
		$('.re-mobile-list-item.re-nav-open, .js-nav-mobile .js-nav-list-item-primary.re-nav-open')
			.removeClass(values.navOpenClass)
			.find(selectors.navSecondary)
			.attr('aria-hidden', true);
		
		// style ui
		$([selectors.mobileNavToggle, selectors.navMobile].join()).addClass(values.navOpenClass);

		// update page layout
		values.positionScroll = elements.window.scrollTop();
		elements.body.css({
			top: generalUtilities.stringFormat('-{0}px', values.positionScroll)
		});
		elements.html.addClass('re-compact-overflow-y-hidden');

		// open active menu item - add open class to parent and remove aria-hidden from all sub navs
		$('.js-nav-mobile .re-nav-item-active:not(.js-experience-switcher)')
			.addClass(values.navItemOpenClass)
			.parent()
			.addClass(values.navOpenClass)
			.find(selectors.navSecondary)
			.removeAttr('aria-hidden');
	};

	/**
	 * Toggles mobile menu
	 */
	module.private.handleMobileToggleClick = function handleMobileToggleClick(e) {
		var opening;

		e.preventDefault();

		opening = !$(selectors.navMobile).hasClass(values.navOpenClass);

		module.private.closeMobileMenu(opening);

		if (opening) {
			module.private.openMobileMenu();
		}
	};

	module.private.handleClickActivityItem = function handleClickActivityItem() {
		values.scrollTop = 0;
		module.private.closeMobileMenu();
	};

	//#endregion

	/**
	 * Initiates the module, creates a guid and binds dom events.
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */
	module.private.init = function init(e, data) {
		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();
		elements.navMobileToggle = $(selectors.mobileNavToggle);
		elements.html = $('html');
		elements.body = $('body');
		elements.window = $(window);
		elements.navMobile = $(selectors.navMobile);

		// bind to hamburger icon on click event
		elements.navMobileToggle.on('click', module.private.handleMobileToggleClick);
		elements.navMobile.on('click', selectors.activityItem, module.private.handleClickActivityItem);
	};

	//#region external event bindings

	module.private.init();

	//#endregion

	// expose API
	return module;
});
